<template>
    <div id="main" class="mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="row no-gutters">
                    <b-tabs v-model="currentCandidateTab" small card class="tab-list driver-tab-list">
                        <b-tab title="Primary Info">
                            <PersonalInfo :personalinfo="personalinfo" :newAdditionalField="newAdditionalField" ref="personalinfocomponent" :candContactAccess="candContactAccess"></PersonalInfo>
                        </b-tab>
                        <b-tab title="Address Info">
                            <ContactInfo :contactinfo="contactinfo" ref="contactinfocomponent"></ContactInfo>
                        </b-tab>
                        <b-tab title="Employment History">
                            <EmploymentInfo :employmentinfo="employmentinfo" ref="employmentinfocomponent"></EmploymentInfo>
                        </b-tab>
                        <b-tab title="Reference Details">
                            <ReferenceInfo :referenceinfo="employmentinfo"></ReferenceInfo>
                        </b-tab>
                        <b-tab title="Technology/Skills">
                            <TechnologyInfo :technologyinfo="technologyinfo" :techinfo="techinfo" ref="technologyinfocomponent"></TechnologyInfo>
                        </b-tab>
                        <b-tab title="Projects">
                            <ProjectInfo :projectinfo="projectinfo" ref="projectinfocomponent"></ProjectInfo>
                        </b-tab>
                        <b-tab title="Preferences">
                            <PreferenceInfo :preferenceinfo="preferenceinfo" ref="preferenceinfocomponent"></PreferenceInfo>
                        </b-tab>
                        <!-- <b-tab title="Availability">
                            <AvailabilityInfo :availabilityinfo="availabilityinfo" ref="availabilityinfocomponent"></AvailabilityInfo>
                        </b-tab> -->
                        <b-tab title="Resume">
                            <ResumeAttach :resumeinfo="resumeinfo" :isResumeCapture="isResumeCapture" :attachments="attachments" :attachmentTypes="attachmentTypes" :multipleFile="multipleFile" ref="resumeattachcomponent"></ResumeAttach>
                        </b-tab>
                        <b-tab title="Screening Results">
                            <Screening :screeninginfo="screeninginfo" ref="screeningcomponent"></Screening>
                        </b-tab>
                        <b-tab title="Settings">
                            <Settings :settingsInfo="settingsInfo" ref="settingscomponent"></Settings>
                        </b-tab>
                    </b-tabs>
                    <div class="col-md-12 pt-2 mt-3 text-right">
                        <router-link :to="{ name: 'referralcandidates' }" class="btn btn-secondary mr-2 text-white">Cancel</router-link>
                        <a href="Javascript:void(0);" v-if="currentCandidateTab!=0" @click="currentCandidate" class="btn btn-primary necttab mr-2">Previous</a>
                        <a href="Javascript:void(0);" v-if="currentCandidateTab<maxtabs" @click="moveNextTab" class="btn btn-primary necttab mr-2">Next</a>
                        <a href="Javascript:void(0);" @click="submitInfo" class="btn btn-primary ">Finish</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style type="text/css">
.disabledTab {
    pointer-events: none;
}

.actionlinks {
    padding: 6px;
    margin-left: 10px;
    text-decoration: none !important;
}

.actionprimary {
    background: rgba(255, 255, 255, 0) !important;
    right: -1px !important;
    border: 0px !important;
    color: #34a1e5 !important;
}

.clearright {
    right: 0px !important;
}

.enablefield {
    background-color: white !important;
    width: 100%;
}

.input-group>div {
    width: 100% !important;
}

.vdp-datepicker__calendar .cell {
    padding: 0px !important;
}

</style>
<script>
import iziToast from 'izitoast';
import PersonalInfo from '@/components/candidates/blocks/addcandidate/PersonalInfo';
import ContactInfo from '@/components/candidates/blocks/addcandidate/ContactInfo';
import EmploymentInfo from '@/components/candidates/blocks/addcandidate/EmploymentInfo';
import ReferenceInfo from '@/components/candidates/blocks/addcandidate/ReferenceInfo';
import TechnologyInfo from '@/components/candidates/blocks/addcandidate/TechnologyInfo';
import PreferenceInfo from '@/components/candidates/blocks/addcandidate/PreferenceInfo';
import ResumeAttach from '@/components/candidates/blocks/addcandidate/ResumeAttach';
import Screening from '@/components/candidates/blocks/addcandidate/ScreeningResult';
import Settings from '@/components/candidates/blocks/addcandidate/Settings';
import ProjectInfo from '@/components/candidates/blocks/addcandidate/ProjectInfo';
export default {
    components: {
        PersonalInfo,
        ContactInfo,
        EmploymentInfo,
        ReferenceInfo,
        TechnologyInfo,
        PreferenceInfo,
        ResumeAttach,
        Screening,
        Settings,
        ProjectInfo
    },
    data() {
        return {
            currencies: this.$store.getters.getCurrencies,
            currentCandidateTab: -1,
            maxtabs: 9,
            candidateinfo: {},
            personalinfo: {
                candidateCode: "",
                firstName: "",
                middleName: "",
                lastName: "",
                email: "",
                countryCode: "+1",
                mobile: "",
                dateOfBirth: "",
                dateAvailable: "",
                position: "",
                industryId: "",
                location: "",
                experience: "",
                expMonth: "",
                expType: "Years",
                referredBy: "",
                payMode: "",
                recruiterId: "",
                referralId: "",
                visaTypeId: "",
                visaType: "",
                notes: "",
                educationinfo: [],
                blockpersonaltab: 0,
                candidateLanguageSet: [],
                candidateLanguage: [],
                sourcingChannel: "",
                sourcingRefLink: "",
                linkedInUrl: "",
                candidateAdditionalFields: [],
                status: 1,
                candidateType: "",
                extractedResume: "",
                willRelocate: "",
                editIndex: true
            },
            contactinfo: {
                currentaddress: {
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "US"
                },
                permanentaddress: {
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "US"
                },
                emergency: []
            },
            employmentinfo: [],
            technologyinfo: [],
            techinfo: [], // console error
            projectinfo: [],
            preferenceinfo: {
                basepreference: [],
                otherpreference: {
                    timetocall: "",
                    workType: "",
                    expectedSalary: "",
                    currency:"",
                    currencyId:"",
                    blockpreferrancetab: 0,
                    payFrequency: "Per Month",
                    minSalary: "",
                    maxSalary: "",
                    frequencyPercentage: "",
                    parttime: "no",
                    fulltime: "no"
                },
                candidateCommunication: []
            },
            resumeinfo: {
                file: '',
                title: 'Resume Upload',
                supportedTypes: 'Supports only PDF, Doc., Docx file types'
            },
            screeninginfo: [],
            settingsInfo: {
                timeZone: "",
                accessLevel: 1
            },
            newAdditionalField: {
                id: 0,
                nameValueId: 0,
                valueFormatId: "",
                intValue: "",
                stringValue: "",
                dateValue: "",
                booleanValue: ""
            },
            isResumeCapture: false,
            attachments: [],
            attachmentTypes: [],
            multipleFile: [],
            candContactAccess: 0,
        }
    },
    mounted() {
       this.loadCurrency();

    },
    methods: {
        loadCurrency(){
            var currencyId = localStorage.getItem('currencyId');
            this.preferenceinfo.otherpreference.currencyId = currencyId;
            var currency = this.currencies.find((c) => c.id == currencyId);
            if (currency) {
                this.preferenceinfo.otherpreference.currency = currency.symbol;
            }
        },
        currentCandidate(){
             switch (this.currentCandidateTab) {
                
                case 5:
                        this.$refs.projectinfocomponent.addNewProjectInfo();
                        this.saveProjectInformation();
                        this.currentCandidateTab--;
                        break;    
                default:
                    this.currentCandidateTab--;
            }
        },
        moveNextTab() {
            switch (this.currentCandidateTab) {
                case 0:
                    //personal info validation
                    this.$refs.personalinfocomponent.validatePersonalInfo();
                    this.$refs.personalinfocomponent.addEducationInfo();
                    if (this.personalinfo.blockpersonaltab == 0) {
                        this.currentCandidateTab++;
                    }
                    break;
                case 1:
                    //contact info tab
                    this.$refs.contactinfocomponent.addNewContact();
                    this.currentCandidateTab++;
                    break;
                case 2:
                    this.$refs.employmentinfocomponent.addNewEmploymentInfo();
                    this.currentCandidateTab++;
                    break;
                case 4:
                    this.$refs.technologyinfocomponent.addNewSkillset();
                    this.currentCandidateTab++;
                    break;
                case 5:
                    this.$refs.projectinfocomponent.addNewProjectInfo();
                    this.saveProjectInformation();
                    this.currentCandidateTab++;
                    break;
                case 6:
                    this.$refs.preferenceinfocomponent.validatePreferrenceInfo();
                    this.$refs.preferenceinfocomponent.addNewPreferenceInfo();
                    this.$refs.preferenceinfocomponent.saveCommunicationMode();
                    if (this.preferenceinfo.otherpreference.blockpreferrancetab == 0) {
                    this.currentCandidateTab++;
                    }
                    // this.$refs.availabilityinfocomponent.refreshCalendar();
                    break;
                    // case 6:
                    //  //this.$refs.availabilityinfocomponent.addNewAvailabilityInfo();
                    //  this.currentCandidateTab++;
                    //  break;
                default:
                    this.currentCandidateTab++;
            }
        },
        submitInfo() {
            if (this.$refs.personalinfocomponent.validatePersonalInfo() && this.$refs.preferenceinfocomponent.validatePreferrenceInfo()) { 
            switch (this.currentCandidateTab) {
                case 0:
                    //personal info validation
                   if (!this.$refs.personalinfocomponent.validatePersonalInfo()) {
                            return false;
                    }
                    this.$refs.personalinfocomponent.addEducationInfo();
                    break;
                case 1:
                    //contact info tab
                    this.$refs.contactinfocomponent.addNewContact();
                    break;
                case 2:
                    this.$refs.employmentinfocomponent.addNewEmploymentInfo();
                    break;
                case 4:
                    this.$refs.technologyinfocomponent.addNewSkillset();
                    break;
                case 5:
                    this.$refs.projectinfocomponent.addNewProjectInfo();
                    this.saveProjectInformation();
                    break;
                case 6:
                     if (!this.$refs.preferenceinfocomponent.validatePreferrenceInfo()) {
                            return false;
                        }
                    this.$refs.preferenceinfocomponent.addNewPreferenceInfo();
                    this.$refs.preferenceinfocomponent.saveCommunicationMode();
                    //this.$refs.availabilityinfocomponent.refreshCalendar();
                    break;
            }

            let dataType = this.$store.getters.getAdditionalFieldsType;

            let candidateAdditionalFields = [...this.personalinfo.candidateAdditionalFields];

            if (this.newAdditionalField.valueFormatId !== "" && this.newAdditionalField.nameValueId !== "") {
                if (this.newAdditionalField.valueFormatId == dataType.xNumber && this.newAdditionalField.intValue !== "") {
                    candidateAdditionalFields.push({ ...this.newAdditionalField });
                } else if (this.newAdditionalField.valueFormatId == dataType.xText && this.newAdditionalField.stringValue !== "") {
                    candidateAdditionalFields.push({ ...this.newAdditionalField });
                } else if (this.newAdditionalField.valueFormatId == dataType.xYesNo && this.newAdditionalField.booleanValue !== "") {
                    candidateAdditionalFields.push({ ...this.newAdditionalField });
                } else if (this.newAdditionalField.valueFormatId == dataType.xDate && this.newAdditionalField.dateValue !== "") {
                    candidateAdditionalFields.push({ ...this.newAdditionalField });
                }
            }
            //this.personalinfo.candidateAdditionalFields = [...candidateAdditionalFields];

            this.personalinfo.candidateLanguage = [];
            for (var index = 0; index < this.personalinfo.candidateLanguageSet.length; index++) {
                // try { 
                //  this.personalinfo.candidateLanguage.push({languageId:parseInt(this.personalinfo.candidateLanguageSet[index])});
                // } catch(ex){

                // }
                this.personalinfo.candidateLanguage.push({ languageId: this.personalinfo.candidateLanguageSet[index] });
            }


            // console.log(this.personalinfo.candidateLanguage);
            // return;

            var techAndSkill = [];

            for (var i = 0; i < this.technologyinfo.length; i++) {
                if (this.technologyinfo[i].skills.length > 0) {
                    for (var j = 0; j < this.technologyinfo[i].skills.length; j++) {
                        techAndSkill.push({
                            industryId: this.technologyinfo[i].industryId,
                            technologyId: this.technologyinfo[i].technology.id,
                            years: this.technologyinfo[i].experience.value,
                            skillId: this.technologyinfo[i].skills[j].skillId,
                            title: '',
                            isPrimary: (this.technologyinfo[i].isPrimary == null ? false : this.technologyinfo[i].isPrimary)
                        });
                    }
                } else {
                    techAndSkill.push({
                        industryId: this.technologyinfo[i].industryId,
                        technologyId: this.technologyinfo[i].technology.id,
                        years: this.technologyinfo[i].experience.value,
                        skillId: null,
                        title: '',
                        isPrimary: (this.technologyinfo[i].isPrimary == null ? false : this.technologyinfo[i].isPrimary)
                    });
                }
            }
           /*  let mobile = '';
            if (this.personalinfo.mobile != null && this.personalinfo.mobile != '') {
                mobile = this.personalinfo.countryCode + " " + this.personalinfo.mobile;                
            } */
            this.contactinfo.emergency.forEach(function(data) {
                       data.phone = data.phoneNo;                                 
                }); 

            this.candidateinfo = {
                recruiterId: this.personalinfo.recruiterId,
                visaTypeId: this.personalinfo.visaType.id,
                visaTypeText: this.personalinfo.visaType.text,
                candidateCode: this.personalinfo.candidateCode,
                firstName: this.personalinfo.firstName,
                lastName: this.personalinfo.lastName,
                middleName: this.personalinfo.middleName,
                email: this.personalinfo.email,
                mobile: this.personalinfo.mobile,
                dateOfBirth: this.formatDateString(this.personalinfo.dateOfBirth),
                //dateAvailable:this.formatDateString(this.personalinfo.dateAvailable),
                industryId: this.personalinfo.industryId,
                position: this.personalinfo.position,
                location: this.personalinfo.location,
                experience: this.personalinfo.experience,
                expMonth: this.personalinfo.expMonth,
                expType: this.personalinfo.expType,
                referredBy: this.personalinfo.referredBy,
                sourcingChannelId: this.personalinfo.sourcingChannel,
                sourcingRefLink: this.personalinfo.sourcingRefLink,
                linkedInUrl: this.personalinfo.linkedInUrl,
                payMode: this.personalinfo.payMode,
                notes: this.personalinfo.notes,
                timeToCall: this.preferenceinfo.otherpreference.timetocall,
                workType: this.preferenceinfo.otherpreference.workType,
                expectedSalary: this.preferenceinfo.otherpreference.expectedSalary,
                currency: this.preferenceinfo.otherpreference.currency,
                currencyId: this.preferenceinfo.otherpreference.currencyId,
                payFrequency: this.preferenceinfo.otherpreference.payFrequency,
                minSalary: this.preferenceinfo.otherpreference.minSalary,
                maxSalary: this.preferenceinfo.otherpreference.maxSalary,
                frequencyPercentage: this.preferenceinfo.otherpreference.frequencyPercentage,
                isPartTime: (this.preferenceinfo.otherpreference.parttime == 'yes') ? true : false,
                isFullTime: (this.preferenceinfo.otherpreference.fulltime == 'yes') ? true : false,
                candidateAddress: {
                    currentAddress: this.contactinfo.currentaddress,
                    permanentAddress: this.contactinfo.permanentaddress,
                    emergencyContact: this.contactinfo.emergency
                },
                candidateEducation: this.personalinfo.educationinfo,
                candidateEmployment: this.employmentinfo,
                candidatePreference: this.preferenceinfo.basepreference,
                candidateSkill: techAndSkill,
                timeZone: this.settingsInfo.timeZone,
                accessLevel: this.settingsInfo.accessLevel,
                candidateLanguage: this.personalinfo.candidateLanguage,
                candidateCommunication: this.preferenceinfo.candidateCommunication,
                candidateAdditionalFields: [...candidateAdditionalFields],
                referralId: this.$store.getters.currentUser.profileId,
                candidateType: this.personalinfo.candidateType,
                willRelocate: this.personalinfo.willRelocate,
                candidateProject: this.projectinfo

            };
            console.log(this.candidateinfo);
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/add/candidate', this.candidateinfo)
                .then(response => {
                    if (response.data.status == 'ACCEPTED') {
                        if (this.resumeinfo.attachments != null && this.resumeinfo.attachments.length > 0) {
                            let formData = new FormData();

                            for (const row of Object.keys(this.resumeinfo.attachments)) {
                                formData.append('files', this.resumeinfo.attachments[row]);
                            }
                            for (const row of Object.keys(this.attachmentTypes)) {
                                formData.append('attachmentTypes', this.attachmentTypes[row]);
                            }
                            formData.append('id', candidateId);

                            axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/uploadresume/multiple', formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                })
                                .then(response => {
                                    let result = response.data;
                                    if (result.status == "ACCEPTED") {
                                        this.resumeinfo.filename = "";
                                        iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                                    } else {
                                        iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                                    }
                                })
                                .catch(error => {
                                    this.unAuthorized(error);
                                    console.log(error.data);
                                });
                        }

                        if (this.resumeinfo.file != '') {
                            let formData = new FormData();
                            formData.append('file', this.resumeinfo.file);
                            formData.append('id', response.data.id);
                            axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/uploadresume', formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }).then(response => {
                                    iziToast.success({
                                        title: 'Success',
                                        message: this.$config.CandidProWitRes,
                                        position: 'topRight'
                                    });
                                    // this.$router.push('/candidates/edit/'+ response.data.id);
                                    this.$router.push('/referral/candidates');
                                })
                                .catch(error => {
                                    iziToast.error({
                                        title: '',
                                        message: this.$config.CandidProCreErr,
                                        position: 'topRight'
                                    });
                                    //this.$router.push('/candidates/edit/'+ response.data.id);
                                    this.$router.push('/referral/candidates');
                                });
                        } else {
                            iziToast.success({
                                title: 'Success',
                                message: this.$config.CandidProWitOutRes,
                                position: 'topRight'
                            });
                            //this.$router.push('/candidates/edit/'+ response.data.id);
                            this.$router.push('/referral/candidates');
                        }
                    } else {
                        iziToast.error({
                            title: 'Error Occured',
                            message: response.data.message,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occured',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                });
                  }
            else{
                if(this.$refs.preferenceinfocomponent.validatePreferrenceInfo()){
                    this.currentCandidateTab = 0;
                }
                else
                this.currentCandidateTab = 6;
            }
        },
        saveProjectInformation() {
            for (var i = 0; i < this.projectinfo.length; i++) {
                if (this.projectinfo[i].projectStartDate1 != "") {
                    this.projectinfo[i].projectStartDate = this.projectinfo[i].projectStartDate1 + "-11";
                }
                if (this.projectinfo[i].projectEndDate1 != "") {
                    this.projectinfo[i].projectEndDate = this.projectinfo[i].projectEndDate1 + "-11";
                }
            }

        },
    }
}

</script>
