<template>
    <div>
        <div class="card">
            <div class="card-header p-2">
                <div class="row">
                    <div class="col-md-6">
                        Personal Info
                    </div>
                    <div class="col-md-6 text-right">
                        {{vendorinfo.leadCode}}
                    </div>
                </div>
            </div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">                      

                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Company Name</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="text" v-model="vendorinfo.companyName" maxlength="50" class="form-control text-required">
                                        <span class="required-icon">
                                        </span>
                                    </div>
                                    <label v-if="$v.vendorinfo.companyName.$error && !$v.vendorinfo.companyName.required" class="text-danger">Please enter company name</label>
                                    <label v-if="$v.vendorinfo.companyName.$error && !$v.vendorinfo.companyName.minLength" class="text-danger">Company name must have at least {{$v.vendorinfo.companyName.$params.minLength.min}} letters.</label>
                                    <label v-if="$v.vendorinfo.companyName.$error && !$v.vendorinfo.companyName.maxLength" class="text-danger">Company name must not exceed {{$v.vendorinfo.companyName.$params.maxLength.max}} letters.</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Industry</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12 input-group">
                                    <select v-model="vendorinfo.industryId" class="form-control">
                                        <option value="">Select</option>
                                        <option v-for="(info, index) in industries" :value="info.id">{{info.industryName}}</option>
                                    </select>                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Contact Person</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" v-model="vendorinfo.name" maxlength="100" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>   
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Email</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                     <div class="input-group">
                                        <input type="email" class="form-control" v-model="vendorinfo.email" maxlength="50">                                       
                                    </div>                                    
                                    <label v-if="$v.vendorinfo.email.$error && !$v.vendorinfo.email.email" class="text-danger">Email Id not valid</label>                                 
                                    <label v-if="$v.vendorinfo.email.$error && !$v.vendorinfo.email.requiredIf" class="text-danger">Please enter Email or Phone or Linkedin</label>                                 
                                </div>
                            </div>
                        </div>                                              
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Phone</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <PhoneNumber ref="phoneNumber" :value="vendorinfo.phone" @phone-input="phoneControlInput"/>
                                    <!-- <div class="input-group phone-border">
                                        <select class="form-control box-none col-sm-3 pl-0 pr-0 max-w-45 border-bottom-0" v-model="vendorinfo.countryCode">
                                            <option value="+1">+1</option>
                                            <option value="+91">+91</option>
                                        </select>
                                        <input-mask class="form-control box-none col-sm-9 border-bottom-0" v-model="vendorinfo.phone" mask="(999)-999-9999" placeholder="Phone No" :alwaysShowMask="false" maskChar="_"></input-mask>
                                    </div>                                     -->
                                   <!--  <div class="input-group">
                                        <input type="text" v-model="vendorinfo.phone" maxlength="100" class="form-control">
                                    </div> -->
                                    <label v-if="$v.vendorinfo.phone.$error && !$v.vendorinfo.phone.requiredIf" class="text-danger">Please enter Email or Phone or Linkedin</label>                                 
                                </div>
                            </div>
                        </div>
                         <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Linkedin Url</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="url" v-model="vendorinfo.linkedinUrl" maxlength="100" class="form-control">
                                    </div>                               
                                    <label v-if="$v.vendorinfo.linkedinUrl.$error && !$v.vendorinfo.linkedinUrl.requiredIf" class="text-danger">Please enter Email or Phone or Linkedin</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header p-2">Address</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Street</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" v-model="vendorinfo.street" maxlength="100" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Country</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <select v-model="vendorinfo.countryId" class="form-control">
                                        <option value="">Select</option>
                                        <option v-for="(data, index) in countries" :value="data.country_Id">{{data.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>                        
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>State</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" v-model="vendorinfo.stateName" maxlength="100" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>City</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" v-model="vendorinfo.cityName" maxlength="100" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Zip Code</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" v-model="vendorinfo.zipCode" maxlength="100" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
        <div class="card">
            <div class="card-header p-2">Social Connect</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                         <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Website</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="url" v-model="vendorinfo.websiteUrl" maxlength="100" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Facebook Url</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="url" v-model="vendorinfo.facebookUrl" maxlength="100" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Twitter Url</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="url" v-model="vendorinfo.twitterUrl" maxlength="100" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Instagram Url</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="url" v-model="vendorinfo.instagramUrl" maxlength="100" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs, requiredIf } from "vuelidate/lib/validators";
export default {
    props: {
        vendorinfo: Object,
    },
    data() {
        return {
            industries: this.$store.getters.getIndustries,
            countries: this.$store.getters.getCountries,
        }
    },
    validations: {
        vendorinfo: {
            companyName: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(200)
            },
            email:{
                 required: requiredIf(function() {
                    // console.log(this.$refs.phoneNumber.$v.phone.required)
                    return !(this.vendorinfo.linkedinUrl || this.vendorinfo.phone);
                }),
                email
            },
            linkedinUrl:{
                 required: requiredIf(function() {
                    return !(this.vendorinfo.email || this.vendorinfo.phone);
                 })
            },
            phone:{
               required: requiredIf(function() {
                 return !(this.vendorinfo.linkedinUrl || this.vendorinfo.email);
                })
            }
        }
    },
    mounted() {
    },
    methods: {
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
         phoneControlInput: function (value) {
            this.vendorinfo.phone = value;
        },
        validatevendorinfo() {
            this.$v.vendorinfo.$touch();
            if (this.$v.vendorinfo.$invalid) {
                return false;
            }
            return true;
        },
        getCountryName: function(countryCode) {
            if (!countryCode)
                return "";
            let country = this.countries.filter(obj => {
                return obj.country_Id === countryCode;
            });
            return country[0].name;
        },
    }
}

</script>
