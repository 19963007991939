  <template>
    <div id="interviewer-edit-block" class="interviewer-edit-block mt-70">
        <div class="container-fluid driver-model" id="main-data">
            <div class="row">
                <div class="col-md-3">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card border-5 mb-3">
                                <h6 class="about-box m-0 text-left p-2">
                                    <a href="javascript:" @click="$router.go(-1)" title="Back" class="p-2">
                                        <i class="ti-arrow-left "></i>
                                        <span> Back</span>
                                    </a>
                                    <router-link class="float-right link pr-2" title="Edit Referral" :to="{ name: 'editreferral', params: {referralid: profileId} }"><i class="fa fa-pencil"></i> Edit </router-link>
                                </h6>
                                <div class="user-card pl-5 pr-5 pt-3">
                                    <div class="information">
                                        <div class="imgUp">
                                             <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' +  (ProfileData.image===''?ProfileData.imagePath:ProfileData.image) + ')' }"></div>
                                        </div>
                                        <div class="name">{{ProfileData.firstName}} {{ProfileData.lastName}}</div>
                                        <div>{{ProfileData.position}} </div>
                                        <div class="email"><i class="ti-email"></i> {{ProfileData.email}}</div>
                                        <div class="mobile"><i v-if="ContactInfo.mobileNo != null" class="ti-mobile"></i> {{ContactInfo.mobileNo}}</div>
                                    </div>
                                </div>
                                <div v-if="this.verified == 'No'">
                                    <h5 class="about-box m-0 text-left p-2">
                                        <span class="job-link pt-0 pb-0">
                                            <a title="Reject" href="javascript:void(0);" data-toggle="modal" data-target="#ReferralRejectRequest"><i class="ti-close" style="color: red;"></i><span style="color: red; padding-right:10px; font-family: sans-serif;"> Reject</span></a>
                                        </span>
                                        <span class="job-link float-right pt-0 pb-0">
                                            <a title="Accept" href="javascript:void(0);" data-toggle="modal" data-target="#ReferralAcceptRequest"><i class="ti-check" style="color: green;"></i><span class="pr-10" style="color: green; font-family: sans-serif;">Accept</span></a>
                                        </span>
                                    </h5>
                                </div>
                            </div>
                            <div class="card border-5">
                                <div class="user-card p-0">
                                    <h5 class="about-box m-0 text-left p-2">About Me</h5>
                                    <div class="p-3 float-left w-100">
                                        <h5>Company</h5>
                                        <div class="informations">
                                            <span v-if="ProfileData.company == undefined" class="badge p-2 mb-1">No records found</span>
                                            <span v-if="ProfileData.companyName != ''">{{ProfileData.company}}</span>
                                        </div>
                                        <div v-if="ContactInfo!== null" class="w-100 float-left">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="candidateCount != 0 || interviewerCount != 0 || clientCount != 0" class="card border-5">
                                <div class="user-card p-0">
                                    <h5 class="about-box m-0 text-left p-2">Referral Info</h5>
                                    <div class="p-3 float-left w-100">
                                        <div v-if="candidateCount != 0" class="value"> <img class="hover-hand" title="Candidate Count" src="@/assets/icons/user.png" alt="">
                                                <span class="pl-5 font-weight-bold">{{candidateCount}}</span>
                                        </div>
                                        <span v-if="candidateCount != 0" class="font-weight-bold">Candidate Count<hr></span>
                                        
                                        <div v-if="interviewerCount != 0" class="value"> <img class="hover-hand" title="Interviewer Count" src="@/assets/icons/interview (1).png" alt="">
                                                <span class="pl-5 font-weight-bold">{{interviewerCount}}</span>
                                        </div> 
                                        <span v-if="interviewerCount != 0" class="font-weight-bold">Interviewer Count<hr></span>
                                        
                                        <div v-if="clientCount != 0" class="value"> <img class="hover-hand" title="Client Count" src="@/assets/icons/support.png" alt="">
                                                <span class="pl-5 font-weight-bold">{{clientCount}}</span>
                                        </div>  
                                        <span v-if="clientCount != 0" class="font-weight-bold">Client Count</span>                                                                           
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="box-wrapper shadow-sm bg-white rounded pos-rel main-search">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row no-gutters">
                                    <div class="col-md-12 pop-tab">
                                        <div class="driver-model">
                                            <div class="row no-gutters">
                                                <b-tabs v-model="currentTab" small card class="tab-list driver-tab-list tab-bg">
                                                    <b-tab title="Primary Info">
                                                        <PersonalInfo :ProfileData=ProfileData ref="personalinfocomponent"></PersonalInfo>
                                                    </b-tab>
                                                    <b-tab title="Contact Info">
                                                        <ContactInfo :ContactInfo=ContactInfo ref="contactInfocomponent"></ContactInfo>
                                                    </b-tab>
                                                      <b-tab title="Settings">
                                                        <Settings :settingsInfo="settingsInfo" :profileId="profileId" @loadReferral="loadReferral" ref="settingscomponent"></Settings>
                                                    </b-tab>
                                                    <!-- <b-tab title="Account Info">
                                                            <AccountInfo ref="contactInfocomponent"/>
                                                        </b-tab> -->
                                                </b-tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal " id="ReferralRejectRequest" tabindex="-1" role="dialog" aria-labelledby="ReferralRejectRequest" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Referral confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to reject this request?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="referralConfirmation('reject')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal " id="ReferralAcceptRequest" tabindex="-1" role="dialog" aria-labelledby="ReferralAcceptRequest" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Referral confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to accept this request?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="referralConfirmation('approve')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PersonalInfo from '@/components/referral/profile/blocks/view/PersonalInfo';
import ContactInfo from '@/components/referral/profile/blocks/view/ContactInfo';
import Settings from '@/components/referral/blocks/referral/Settings';
import iziToast from 'izitoast';
/*import AccountInfo from '@/components/referral/profile/blocks/view/AccountInfo';
 */
export default {
    components: {
        PersonalInfo,
        ContactInfo,
        Settings
        /*AccountInfo*/
    },
    data() {
        return {
            profileId: this.$route.params.referralid,
            verified: this.$route.params.verified,
            elements: {
                showAvailabilityInfo: false
            },
            currentTab: 0,

            ProfileData: {
                id: 0,
                firstName: '',
                lastName: '',
                nickName: '',
                email: '',
                dateOfBirth: '',
                gender: '',
                position: '',
                image: '',
                imageName: '',
                imagePath: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',
                image: '',
                industryId: null,
                company: ''
            },
            candidateCount: '',
            interviewerCount: '',
            clientCount: '',
            ContactInfo: Object,
             settingsInfo: {
                timezone: "",
                accessLevel: 1
            },
            colours: ['badge-secondary', 'badge-primary', 'badge-success', 'badge-danger', 'badge-warning', 'badge-info']
        }
    },
    mounted() {
        this.loadReferral();
        this.loadCount();
    },
    methods: {
        loadCount: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/referralresource/referral/info/count/' + this.profileId)
                .then(response => {
                    this.interviewerCount = response.data.interviewers;
                    this.candidateCount = response.data.candidates;
                    this.clientCount = response.data.clients;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadReferral: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/referralresource/view/referral/' + this.profileId)
                .then(response => {
                    if (response.data.status) {
                        let referralData = response.data.referral;
                        this.ProfileData.id = referralData.id;
                        this.ProfileData.firstName = referralData.firstName;
                        this.ProfileData.lastName = referralData.lastName;
                        this.ProfileData.nickName = referralData.nickName;
                        this.ProfileData.email = referralData.email;
                        this.ProfileData.dateOfBirth = referralData.dateOfBirth;
                        this.ProfileData.gender = referralData.gender;
                        this.ProfileData.position = referralData.position;
                        this.ProfileData.imageName = referralData.imageName;
                        this.ProfileData.imagePath = (referralData.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : referralData.imagePath);
                        this.ProfileData.image = (referralData.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : referralData.imagePath);
                        this.ProfileData.industryId = referralData.industryId;
                        this.ProfileData.company = referralData.companyName;
                        this.ContactInfo = Object.assign({}, referralData.referralContact);
                        this.settingsInfo.timezone = referralData.timezone == null ? '' : referralData.timezone;
                    }
                    
                })
                .catch(error => {
                    console.log(error);
                });
        },
        referralConfirmation(type) {
            axios.post(this.$store.getters.getAPIBasePath + '/referralresource/referral/approval', { 'id': this.profileId, 'statusName': type })
                .then(response => {
                    let result = response.data;
                    if (result.status) {
                        iziToast.success({
                            title: 'Confirmation Request',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.loadReferral();
                        this.$router.go(-1);
                    } else {
                        iziToast.error({
                            title: 'Confirmation Request',
                            message: this.$config.ConfirmReqErr,
                            position: 'topRight'
                        });
                    }
                });
            return true;
        }
    }
}

</script>
