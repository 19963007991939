<template>
    <div>
        <div class="card">
            <div class="card-header p-2">Personal Info</div>
            <div class="card-body p-2">
                <div class="basic-block col-md-12">
                    <div class="table-responsive">
                        <table class="table table-striped table-borderless s_table compare-table">
                            <tbody>
                                <tr col="row">
                                    <td col="col-md-3" ><strong>Company Name</strong></td>
                                    <td col="col-md-3" >{{vendorinfo.companyName}}</td>                                
                                    <td col="col-md-3 bl-5"><strong>Lead Code</strong></td>
                                    <td col="col-md-3" >{{vendorinfo.leadCode}}</td>
                                </tr>
                                <tr>
                                    <td col="col-md-3"><strong>Industry</strong></td>
                                    <td col="col-md-3" >{{ (vendorinfo.industry != null) ? vendorinfo.industry.industryName :""}}</td>                             
                                    <td col="col-md-3 bl-5"><strong>Contact Person</strong></td>
                                    <td col="col-md-3" >{{vendorinfo.name}}</td>
                                </tr>
                                <tr>
                                    <td col="col-md-3"><strong>Email</strong></td>
                                    <td col="col-md-3" >{{vendorinfo.email}}</td>                                
                                    <td col="col-md-3 bl-5"><strong>Phone</strong></td>
                                    <td col="col-md-3" >{{vendorinfo.phone}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header p-2">Address</div>
            <div class="card-body p-2">
                <div class="basic-block col-md-12">
                    <div class="table-responsive">
                        <table class="table table-striped table-borderless s_table compare-table">
                            <tbody>
                                <tr col="row">
                                    <td col="col-md-3"><strong>Street</strong></td>
                                    <td col="col-md-3" >{{vendorinfo.street}}</td>                              
                                    <td col="col-md-3"><strong>Country</strong></td>
                                    <td col="col-md-3">{{vendorinfo.countryId}}</td>
                                </tr>
                                <tr>
                                    <td col="col-md-3"><strong>State</strong></td>
                                    <td col="col-md-3">{{vendorinfo.stateName}}</td>                               
                                    <td col="col-md-3"><strong>City</strong></td>
                                    <td col="col-md-3">{{vendorinfo.cityName}}</td>
                                </tr>
                                <tr>
                                    <td col="col-md-3"><strong>Zipcode</strong></td>
                                    <td col="col-md-3">{{vendorinfo.zipCode}}</td>
                                    <td col="col-md-3"><strong>&nbsp;</strong></td>
                                    <td col="col-md-3">&nbsp;</td>
                                </tr>                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>       
        <div class="card">
            <div class="card-header p-2">Social Connect</div>
            <div class="card-body p-2">
                <div class="basic-block col-md-12">
                    <div class="table-responsive">
                        <table class="table table-striped table-borderless s_table compare-table">
                            <tbody>
                                <tr col="row">
                                    <td col="col-md-3"><strong>Website </strong></td>
                                    <td col="col-md-3">{{vendorinfo.websiteUrl}}</td>                             
                                    <td col="col-md-3"><strong>Linkedin Url</strong></td>
                                    <td col="col-md-3">{{vendorinfo.linkedinUrl}}</td>
                                </tr>
                                <tr>
                                    <td col="col-md-3"><strong>Facebook Url</strong></td>
                                    <td col="col-md-3">{{vendorinfo.facebookUrl}}</td>                                
                                    <td col="col-md-3"><strong>Twitter Url</strong></td>
                                    <td col="col-md-3">{{vendorinfo.twitterUrl}}</td>
                                </tr>
                                <tr>
                                    <td col="col-md-3"><strong>Instagram Url</strong></td>
                                    <td col="col-md-3">{{vendorinfo.instagramUrl}}</td>
                                    <td col="col-md-3"><strong>&nbsp;</strong></td>
                                    <td col="col-md-3">&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        vendorinfo: Object,
    },
    data() {
        return {}
    },
    methods: {}
}

</script>
