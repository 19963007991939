<template>
    <div id="main" class="template-settings settings recruiter mt-70">
        <div class="row col-md-12 box-wrapper shadow-sm my-4 mx-0 px-0 rounded pos-rel">
            <div class="col-md-3 settings-leftbar px-0 bg-white">
                <div class="p-4 row header mx-0">
                    <div class="col-md-9 px-0">
                        <h5>Tools & Settings</h5>
                    </div>
                    <div class="col-md-3 px-0 text-right">
                        <router-link v-if="type!=1" :to="recownerid==0?{name: 'candidatesdashboard'}:{name: 'rmdashboard'}">
                            <h5 class="text-blue"><span class="ti-arrow-left py-4" title="Go Back"></span><span class="pl-2">Back</span></h5>
                        </router-link>
                        <router-link v-if="type==1" :to="{ name: 'addinterviewer' }">
                            <h5 class="text-blue"><span class="ti-arrow-left py-4" title="Go Back"></span><span class="pl-2">Back</span></h5>
                        </router-link>
                    </div>
                </div>
                <div @click="settingsTab" class="row col-md-12 py-4 mx-0 left-bar-tab" :class="elements.activeTab==1?'left-tab-active':''">
                    <div class="col-md-3">
                        <img src="@/assets/icons/settings-border.png" width="90%" height="90%">
                    </div>
                    <div class="col-md-8">Settings</div>
                </div>
                <div @click="toolsTab" class="row col-md-12 py-4 mx-0 left-bar-tab" :class="elements.activeTab==2?'left-tab-active':''">
                    <div class="col-md-3">
                        <img src="@/assets/icons/tools.png" width="85%" height="85%">
                    </div>
                    <div class="col-md-8">Tools</div>
                </div>
                <div @click="questionsTab" class="row col-md-12 py-4 mx-0 left-bar-tab" :class="elements.activeTab==3?'left-tab-active':''">
                    <div class="col-md-3">
                        <img src="@/assets/icons/questionnaire.png" width="85%" height="85%">
                    </div>
                    <div class="col-md-8">Question Bank</div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="basic-block p-4 bg-white">
                    <Settings v-if="elements.activeTab==1 && elements.subActiveTab==0" @setSubTab="setSubTab" />
                    <TechSkillSettings :type="type" v-if="elements.activeTab==1 && elements.subActiveTab==1" @setSubTab="setSubTab" />
                    <DomainSettings v-if="elements.activeTab==1 && elements.subActiveTab==2" @setSubTab="setSubTab" />
                    <SourceChannelSettings v-if="elements.activeTab==1 && elements.subActiveTab==3" @setSubTab="setSubTab" />
                    <AdditionalSettings v-if="elements.activeTab==1 && elements.subActiveTab==4" @setSubTab="setSubTab" />
                    <EmailAccountSettings v-if="elements.activeTab==1 && elements.subActiveTab==5 && elements.action=='list'" @setSubTab="setSubTab" @setAction="setAction" />
                    <AddEmailAccount v-if="elements.activeTab==1 && elements.subActiveTab==5 && elements.action=='add'" @closeAction="elements.action='list'" />
                    <ViewEmailAccount v-if="elements.activeTab==1 && elements.subActiveTab==5 && elements.action=='view'" @setAction="setAction" @closeAction="elements.action='list'" :settingsEmailAccountId="elements.actionId" />
                    <EditEmailAccount v-if="elements.activeTab==1 && elements.subActiveTab==5 && elements.action=='edit'" @setAction="setAction" @closeAction="elements.action='list'" :settingsEmailAccountId="elements.actionId" />
                    <TaskActivitySettings v-if="elements.activeTab==1 && elements.subActiveTab==6" @setSubTab="setSubTab" />
                    <GeneralSettings v-if="elements.activeTab==1 && elements.subActiveTab==7" @setSubTab="setSubTab" />
                    <Tools v-if="elements.activeTab==2 && elements.subActiveTab==0" @setSubTab="setSubTab" />
                    <EmailTools v-if="elements.activeTab==2 && elements.subActiveTab==1" @setSubTab="setSubTab" />
                    <BulkUpload v-if="elements.activeTab==2 && elements.subActiveTab==2" @setSubTab="setSubTab" class="mt-0" />
                    <QuestionBank v-if="elements.activeTab==3 && elements.subActiveTab==0" @setSubTab="setSubTab" />
                    <Questionaries v-if="elements.activeTab==3 && elements.subActiveTab==1" @setSubTab="setSubTab" />

                    <WorkAuthSettings v-if="elements.activeTab==1 && elements.subActiveTab==10" @setSubTab="setSubTab" />
                    <JobDocumentsSettings v-if="elements.activeTab==1 && elements.subActiveTab==11" @setSubTab="setSubTab" />
                     <TemplateTypes v-if="elements.activeTab==1 && elements.subActiveTab==14" @setSubTab="setSubTab" />
                      <Industry v-if="elements.activeTab==1 && elements.subActiveTab==15" @setSubTab="setSubTab" />
                    <QualificationSettings v-if="elements.activeTab==1 && elements.subActiveTab==12" @setSubTab="setSubTab" />
                    <TaxTermsSettings v-if="elements.activeTab==1 && elements.subActiveTab==13" @setSubTab="setSubTab" />
                    <CounterOfferSettings v-if="elements.activeTab==1 && elements.subActiveTab==16" @setSubTab="setSubTab" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Settings from './blocks/Settings';
import TechSkillSettings from './blocks/settings/TechSkillSettings';
import DomainSettings from './blocks/settings/DomainSettings';
import SourceChannelSettings from './blocks/settings/SourceChannelSettings';
import AdditionalSettings from './blocks/settings/AdditionalSettings';
import EmailAccountSettings from './blocks/settings/EmailAccountSettings';
import AddEmailAccount from './blocks/settings/emailaccountsettings/AddEmailAccount';
import ViewEmailAccount from './blocks/settings/emailaccountsettings/ViewEmailAccount';
import EditEmailAccount from './blocks/settings/emailaccountsettings/EditEmailAccount';
import TaskActivitySettings from './blocks/settings/TaskActivitySettings';
import GeneralSettings from './blocks/settings/GeneralSettings';
import Tools from './blocks/Tools';
import EmailTools from './blocks/tools/EmailTools';
import BulkUpload from '@/components/candidates/BulkUpload';
import QuestionBank from './blocks/QuestionBank';
import Questionaries from './blocks/questionbank/Questionaries';
import QualificationSettings from './blocks/settings/QualificationSettings';
import WorkAuthSettings from './blocks/settings/WorkAuthSettings';
import JobDocumentsSettings from './blocks/settings/JobDocumentsSettings';
import TemplateTypes from './blocks/settings/TemplateTypes';
import Industry from './blocks/settings/Industry';
import TaxTermsSettings from './blocks/settings/TaxTermsSettings';
import CounterOfferSettings from './blocks/settings/CounterOfferSettings';
export default {
    components: {
        Settings,
        TechSkillSettings,
        DomainSettings,
        SourceChannelSettings,
        AdditionalSettings,
        EmailAccountSettings,
        AddEmailAccount,
        ViewEmailAccount,
        EditEmailAccount,
        TaskActivitySettings,
        GeneralSettings,
        Tools,
        EmailTools,
        BulkUpload,
        QuestionBank,
        Questionaries,
        QualificationSettings,
        WorkAuthSettings,
        JobDocumentsSettings,
        TemplateTypes,
        Industry,
        TaxTermsSettings,
        CounterOfferSettings
    },
    computed: {
        type() {
            return this.$route.params.type;
        }
    },
    data() {
        return {
            elements: {
                activeTab: 1,
                subActiveTab: 0,
                action: "list",
                actionId: null
            },
            recownerid: this.$store.getters.currentUser.recruitOwner
        }
    },
    mounted() {
        if (this.type == 1)
            this.elements.subActiveTab = 1;
    },
    methods: {
        setSubTab: function(subTab) {
            this.elements.subActiveTab = subTab;
        },
        settingsTab: function() {
            this.elements.activeTab = 1;
            this.elements.subActiveTab = 0;
            this.elements.action = "list";
        },
        toolsTab: function() {
            this.elements.activeTab = 2;
            this.elements.subActiveTab = 0;
        },
        questionsTab: function() {
            this.elements.activeTab = 3;
            this.elements.subActiveTab = 0;
        },
        setAction: function(action, actionId = this.elements.actionId) {
            this.elements.action = action;
            this.elements.actionId = actionId;
        }
    }
}

</script>
