<template>
	<div>
		<div class="card">
            <div class="card-header p-2">Add Client Contact</div>
            <div class="card-body p-2">
              <div class="basic-block">
                <div class="row ten-columns">
                  <div class="col-md-6">
                  </div>
                  <!-- <div class="col-md-6 text-right">
                    <button type="button" class="btn btn-primary btn-sm pull-right" @click="resetNewClientContactForm"  data-toggle="modal" data-target="#clientcontact" style="margin-left: 10px;">Add</button>
                  </div> -->
                </div>
                <div class="row ten-columns">
                    <div class="col-sm-12">
                        <div class="table-responsive">
                          <table class="table table-borderless all-table">
                            <thead>
                              <tr>
                                <th class="" scope="col"> Name</th>
                                <th class="recruiter" scope="col">Default Contact</th>
                                <th class="title" scope="col">Title</th>
                                <th class="state" scope="col">Phone</th>
                                <th class="lastnote" scope="col">Mobile</th>
                                <th class="email" scope="col">  Email</th>
                                <th class="gpm" scope="col">  Department</th>
                                <!-- <th class=""  scope="col">Action</th> -->
                              </tr>
                            </thead>
                            <tbody>
                                <tr class="">
                                    <td colspan="7" v-if="clientcontactinfo.length==0" class="text-center">No Client Contact List Found</td>
                                </tr>
                                <tr v-for="(info, index) in clientcontactinfo">
                                    <td> {{ clientcontactinfo[index].contactName }}   </td>
                                    <td class="lastnote"> 
                                      <input :disabled="true" class="form-check-input" id="isdefaultContact" type="checkbox" v-model="clientcontactinfo[index].isDefault" > 
                                       <label class="form-check-label" for="isdefaultContact"></label>
                                     </input>
                                    </td>
                                    <td class="recruiter"> {{ clientcontactinfo[index].title }}  </td>
                                    <td class="title"> {{ clientcontactinfo[index].phoneNumber }} </td> 
                                    <td class="specialityunit"> {{ clientcontactinfo[index].mobile }} </td>
                                    <td class="specialityunit"> {{ clientcontactinfo[index].email }}  </td>
                                    <td class="specialityunit"> {{ clientcontactinfo[index].department }}  </td>
                                    <!-- <td >
                                        <ul class="navbar-nav">
                                            <li class="nav-item dropdown">
                                                <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="Javascript:void(0);" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <li>
                                                        <a class="dropdown-item" data-toggle="modal" data-target="#clientcontact" @click="loadClientContact(clientcontactinfo[index].id)"><span class="ti-pencil"></span> Edit</a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item" href="#" @click="deleteClientContact(clientcontactinfo[index].id)"><span class="ti-close"></span> Remove</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>                                                
                                    </td> -->
                                </tr>
                            </tbody>
                          </table>
                        </div>
                    </div>
                </div>
              </div>
            </div>
    </div>
    <div class="modal fade" id="clientcontact" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel" v-if="isEdit==0">ADD CLIENT CONTACT</h5>
            <h5 class="modal-title" id="exampleModalLabel" v-if="isEdit==1">EDIT CLIENT CONTACT</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card m-b-3">
              <div class="card-body p-2">
                <div class="basic-block">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 lb">
                          <label for="tc_type">First Name</label>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                            <div class="input-group required-field-block">
                                <input type="text" name="firstName"  ref="addclientcontactinfo_add_name" maxlength="25" value="" id="firstName" v-model="newclientcontactinfo.firstName" class="Submitaddress txtwidth form-control"  :class="{ 'is-invalid': $v.newclientcontactinfo.firstName.$error }">
                                <span class="required-icon">
                                </span>
                            </div>
                            <label v-if="$v.newclientcontactinfo.firstName.$error && !$v.newclientcontactinfo.firstName.minLength" class="text-danger">First Name must have at least {{$v.newclientcontactinfo.firstName.$params.minLength.min}} letters.</label>
                            <label v-if="$v.newclientcontactinfo.firstName.$error && !$v.newclientcontactinfo.firstName.required" class="text-danger">Please enter First Name</label>
                            <label v-if="$v.newclientcontactinfo.firstName.$error && !$v.newclientcontactinfo.firstName.maxLength" class="text-danger">First Name must not exceed {{$v.newclientcontactinfo.firstName.$params.maxLength.max}} letters.</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 lb">
                          <label for="tc_type">Middle Name</label>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="input-group">
                            <input type="text" name="middleName" v-model="newclientcontactinfo.middleName" maxlength="25" value="" id="middleName" class="Submitaddress txtwidth form-control">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 lb">
                          <label for="tc_type">Last Name</label>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                            <div class="input-group required-field-block">
                                <input type="text" name="lastName" v-model="newclientcontactinfo.lastName" maxlength="25" value="" id="lastName" class="Submitaddress txtwidth form-control" :class="{ 'is-invalid': $v.newclientcontactinfo.lastName.$error }">
                                <span class="required-icon">
                                </span>
                            </div>
                            <label v-if="$v.newclientcontactinfo.lastName.$error && !$v.newclientcontactinfo.lastName.minLength" class="text-danger">Last Name must have at least {{$v.newclientcontactinfo.lastName.$params.minLength.min}} letters.</label>
                            <label v-if="$v.newclientcontactinfo.lastName.$error && !$v.newclientcontactinfo.lastName.required" class="text-danger">Please enter Last Name</label>
                            <label v-if="$v.newclientcontactinfo.lastName.$error && !$v.newclientcontactinfo.lastName.maxLength" class="text-danger">Last Name must not exceed {{$v.newclientcontactinfo.lastName.$params.maxLength.max}} letters.</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 lb">
                          <label for="tc_type">Title</label>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="input-group">
                            <input type="text" name="title" maxlength="50" v-model="newclientcontactinfo.title" id="title" class="Submitaddress txtwidth form-control">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 lb">
                          <label for="tc_type">Type</label>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                            <div class="input-group">
                                <select name="selectedDivision" id="selectedDivision" v-model="newclientcontactinfo.contactType" class="form-control">
                                    <option value="">Select Type</option>
                                    <option v-for="(data, index) in contacttypes" :value="data.id">{{data.name}}</option>
                                </select>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 lb">
                          <label for="tc_type">Department</label>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="input-group">
                              <input type="text" name="department" v-model="newclientcontactinfo.department" maxlength="20" size="30" value="" id="department" class="Submitaddress txtwidth form-control">   
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 lb">
                          <label for="tc_type">Email</label>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="input-group">
                            <input type="text" name="email" v-model="newclientcontactinfo.email" maxlength="50" size="30" value="" id="email" class="Submitaddress txtwidth form-control" :class="{ 'is-invalid': $v.newclientcontactinfo.email.$error }" >                        
                          </div>
                          <label v-if="$v.newclientcontactinfo.email.$error && !$v.newclientcontactinfo.email.email" class="text-danger">Please enter a valid email</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 lb">
                          <label for="tc_type">Phone</label>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                            <div class="input-group required-field-block">
                                <input type="text" name="phone2" v-model="newclientcontactinfo.phoneNumber" maxlength="15" size="10" value="" id="phone2" class="Submitaddress fldboxbig_nowidth form-control" :class="{ 'is-invalid': $v.newclientcontactinfo.phoneNumber.$error }">
                                <span class="required-icon">
                                </span>
                            </div>                        
                            <label v-if="$v.newclientcontactinfo.phoneNumber.$error && !$v.newclientcontactinfo.phoneNumber.required" class="text-danger">Please enter Phone Number</label>
                            <label v-if="$v.newclientcontactinfo.phoneNumber.$error && !$v.newclientcontactinfo.phoneNumber.maxLength" class="text-danger">Phone Number must not exceed {{$v.newclientcontactinfo.phoneNumber.$params.maxLength.max}} letters.</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 lb">
                          <label for="tc_type">Mobile</label>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="input-group">
                            <input type="text" name="mobile2" v-model="newclientcontactinfo.mobile" maxlength="15" value="" id="mobile2" class="Submitaddress form-control">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 lb">
                          <label for="tc_type">Address</label>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="input-group">
                            <input type="text" name="address" v-model="newclientcontactinfo.address" maxlength="200" value="" id="address" class="Submitaddress form-control">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 lb">
                          <label for="tc_type">City</label>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="input-group">
                            <input type="text" name="city" v-model="newclientcontactinfo.city" maxlength="25" id="city"  class="Submitaddress form-control">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 lb">
                          <label for="tc_type">State</label>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                            <div class="input-group">
                                <select v-if="newclientcontactinfo.countryId=='US'" v-model="newclientcontactinfo.stateName" class="form-control">
                                <option value="">Select State</option>
                                <option v-for="(data, index) in usstates" :value="data.stateName">{{data.stateName}}</option>
                                </select>
                                <input v-if="newclientcontactinfo.countryId!='US'" v-model="newclientcontactinfo.stateName" type="text" maxlength="25" minlength="2" size="23" class="form-control">
                            </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>

                  <div class="row">
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 lb">
                          <label for="tc_type">Zip Code</label>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="input-group">
                            <input type="text" name="zip" v-model="newclientcontactinfo.zipCode" maxlength="10" value="" id="zip" class="Submitaddress form-control">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 lb">
                          <label for="tc_type">Country</label>
                        </div>
                      
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="input-group required-field-block">
                              <select v-model="newclientcontactinfo.countryId" @change="newclientcontactinfo.stateName=''" class="form-control">
                                  <option value="">Select Country</option>
                                  <option v-for="(data, index) in countrieslist" :value="data.country_Id">{{data.name}}</option>
                              </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 lb">
                          <label for="tc_type">Fax</label>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="input-group">
                            <input type="text" name="fax2" v-model="newclientcontactinfo.faxNumber" maxlength="15" size="10" value="" id="fax2" class="Submitaddress fldboxbig_nowidth form-control">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 lb">
                          <label for="tc_type">Notes</label>
                        </div>
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="input-group">
                            <textarea name="notes" rows="3" id="notes" v-model="newclientcontactinfo.notes" class="txtboxbig Submitaddress form-control"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 lb">
                          <div class="form-check form-check-inline">
                            <input type="checkbox" name="defaultContact" value="on" v-model="newclientcontactinfo.isDefault"   id="defaultContact" class="form-check-input form-control">
                            <label class="form-check-label" for="defaultContact">Default contact</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="resetNewClientContactForm"> Reset</button>
            <button type="button" class="btn btn-primary" @click="saveClientContactInfo" > Save</button>
          </div>
        </div>
      </div>
    </div>
	</div>
</template>
<script>
    import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
    import iziToast from 'izitoast';
    export default{
        computed:{
            clientid(){
                return this.$route.params.clientid;
            }
        },
        props: {
            clientcontactinfo: Array,
        },
        data(){
          return {
            isEdit: 0,
            countrieslist:this.$store.getters.getCountries,
            usstates:this.$store.getters.getUSStates,
            contacttypes:this.$store.getters.getClientContactType,
            newclientcontactinfo:{
                id:"",
                department:"",
                city:"",
                stateName:"",
                firstName:"",
                middleName:"",
                lastName:"",
                countryId:"US",
                title:"",
                contactType:"",
                email:"",
                phoneNumber:"",
                address:"",
                zipCode:"",
                faxNumber:"",
                mobile:"",
                notes:"",
                isDefault:0

            },
            formErrors : {}
          }
        },
        validations: {
            newclientcontactinfo:{
                firstName:{
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(30)
                },
                lastName:{
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(25)
                },
                phoneNumber:{
                    required,
                    maxLength: maxLength(15)
                }, 
                email:{
                    email
                }
            }
        },
        methods:{
            resetNewClientContactForm(){
                this.newclientcontactinfo = {
                    id:"",
                    department:"",
                    city:"",
                    stateName:"",
                    firstName:"",
                    middleName:"",
                    lastName:"",
                    countryId:"US",
                    title:"",
                    contactType:"",
                    email:"",
                    phoneNumber:"",
                    address:"",
                    zipCode:"",
                    faxNumber:"",
                    mobile:"",
                    notes:"",
                    isDefault:0
                };
                this.$refs.addclientcontactinfo_add_name.focus();
                this.$v.newclientcontactinfo.$reset();
            },
            loadClientContact(contactId){
                axios.get(this.$store.getters.getAPIBasePath+'/clientresource/view/clientcontact/'+contactId)
                .then(response => {
                    let result = response.data;
                    if(!result.status){
                        iziToast.error({
                            title: 'Error Occured',
                            message: result.message,
                            position:'topRight'
                        });
                    }
                    let self = this;
                    this.newclientcontactinfo = {
                        id:result.clientContact.id,
                        department:result.clientContact.department,
                        city:result.clientContact.city,
                        stateName:result.clientContact.stateName,
                        firstName:result.clientContact.firstName,
                        middleName:result.clientContact.middleName,
                        lastName:result.clientContact.lastName,
                        countryId:result.clientContact.countryId,
                        title:result.clientContact.title,
                        contactType:result.clientContact.contactType,
                        email:result.clientContact.email,
                        phoneNumber:result.clientContact.phoneNumber,
                        address:result.clientContact.address,
                        zipCode:result.clientContact.zipCode,
                        faxNumber:result.clientContact.faxNumber,
                        mobile:result.clientContact.mobile,
                        notes:result.clientContact.notes,
                        isDefault:result.clientContact.isDefault,
                    };
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliConLoadErr,
                        position:'topRight'
                    });
                });
            },
            saveClientContactInfo(){
                this.$v.newclientcontactinfo.$touch();
                let clientcontactinfodata = this.newclientcontactinfo;
                console.log(clientcontactinfodata); 
                axios.post(this.$store.getters.getAPIBasePath+'/clientresource/update/client/'+ this.clientid + '/contact',clientcontactinfodata)
                    .then(response => {
                        iziToast.success({
                            title: 'Success',
                            message: this.$config.CliConInfoUpSuc,
                            position:'topRight'
                        });
                        $('#clientcontact').modal('hide');
                        this.resetNewClientContactForm();
                        this.$emit('refresh-client');
                    })
                    .catch(error => {
                        console.log(error);
                        let data = error.response.data;
                        if (data.message.toLowerCase() == "validation error"){
                            this.formErrors = data.validationErrorList;
                         }
                        iziToast.error({
                            title: 'Error Occured',
                            message: this.$config.CliConUpErr,
                            position:'topRight'
                        });
                    });
            },
            deleteClientContact(clientContactId) {  
               if(confirm("Do you really want to delete?")){            
                   var params = new URLSearchParams();
                   params.append('id', clientContactId)
                   axios.post(this.$store.getters.getAPIBasePath+'/clientresource/delete/clientcontact', params)
                   .then(response => {
                      var result = response.data;
                      if(result.status == "OK") { 
                          iziToast.success({
                              title: 'Success',
                              message: result.message,
                              position:'topRight'
                          }); 
                           this.$emit('refresh-client');
                       }                            
                   })
                   .catch(error => {
                       console.log(error.data);
                   });
                }        
            },
        }
    }
</script>