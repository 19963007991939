<template>
    <div class="recruiter tasks mt-70 sidebar-collapse">
        <div class="row col-md-12 box-wrapper shadow-sm my-4 rounded pos-rel mx-0 px-0">
            <div class="col-md-3 settings-leftbar bg-white px-0">
                <div class="p-4 row header mx-0">
                    <div class="col-md-9 px-0">
                        <h5>Tasks</h5>
                    </div>
                    <div class="col-md-3 px-0 text-right">
                        <router-link :to="{name: recownerid? 'rmdashboard' : 'candidatesdashboard'}">
                            <h5 class="text-blue">
                                <span class="ti-arrow-left py-4" title="Go Back"></span>
                                <span class="pl-2">Back</span>
                            </h5>
                        </router-link>
                    </div>
                </div>
                <div class="d-flex flex-row bg-tturbo-light justify-content-between py-2 px-3 m-0">
                    <span><strong>Projects</strong></span>
                    <span title="Create Project" @click="addLogActivity('Add Project')"><i class="fa fa-plus hover-hand" v-b-modal.createProjectModal></i></span>
                </div>
                <div class="row col-md-12 mx-0 px-0 multiselect-arrow">
                    <multiselect v-model="selected.project" :options="options.projectList" name="project" label="projectName" track-by="id" select-label="" deselect-label="" class="" :max-height="600" placeholder="Select Project" :searchable="true" @select="getSprintList" @remove="options.sprintList.length=0"></multiselect>
                </div>
                <div class="d-flex flex-row bg-tturbo-light justify-content-between py-2 px-3 m-0">
                    <span><span style="cursor: pointer;" v-on:click="isShow = !isShow" :class="isShow?'ti-arrow-circle-up':'ti-arrow-circle-down'"></span> <strong>Task Group</strong></span>
                    <!-- <span v-on:click="isShow = !isShow" style="cursor: pointer;"><strong>Task Group</strong></span> -->
                    <span title="Create Task Group" v-on:click="showTaskGroup()"><i class="fa fa-plus hover-hand"  ></i></span>
                </div>
                <div  v-if="isShow" class="d-flex flex-column bd-highlight mb-3">
                    <!-- <div class="text-right p-2">
                        <a href="javascript:" v-b-modal.createSprintModal>Create Sprint</a>
                    </div> -->
                    <div v-if="options.sprintList.length" v-for="Sprint in options.sprintList" :key="Sprint.id" :class="selected.taskGroupId==Sprint.id?'left-tab-active':''" class="left-bar-tab">
                        <div @click="loadTasks(Sprint.id)" class="p-4">{{ Sprint.name }}</div>
                    </div>
                    <div v-if="!options.sprintList.length">
                        <div class="text-center">No task group found</div>
                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="card" v-if="!showTaskUpdate">
                    <div class="card-header">
                        <span v-if="selected.project && selected.project.projectName">{{ selected.project.projectName }} / {{ getSprintName(selected.taskGroupId) }}</span>
                        <button type="button" v-b-modal.createTaskModal @click="createTask()" class="btn btn-primary btn-sm pull-right mx-1">Create Task</button>
                    </div>
                    <div class="card-body">
                        <div class="d-flex flex-row stages-list">
                            <div v-for="Stage in options.stageList" class="p-2 m-2 stage flex-fill">
                                <div class="stage-header">{{ Stage.name }}</div>
                                <div class="stage-body">
                                    <div v-if="false" class="stage-card hover-hand text-secondary">
                                        <span @click="showTaskModal(Stage.id)" class="task-add-on"><i class="fa fa-plus mr-2"></i>Create Task</span>
                                    </div>
                                    <div v-if="getTaskByStageId(Stage.id).length" v-for="Task in getTaskByStageId(Stage.id)" class="stage-card">
                                        <div class="d-flex justify-content-between">
                                            <a href="javascript:void(0);" @click="viewTask(Task.id,'edit','taskupdate')" class="hover-hand">
                                                <h6 title="Title" style="color:#2980C9;">{{ Task.title }}<span v-if="checkEmpty(Task.taskCode)"> - [{{Task.taskCode}}]</span></h6>
                                            </a>
                                            <span>
                                                <h6>
                                                    <i class="ti-more hover-hand" id="taskActions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                    <ul class="dropdown-menu" aria-labelledby="taskActions">
                                                        <li v-b-modal.createTaskModal @click="viewTask(Task.id,'duplicate','')" class="hover-hand p-1"><i class="fa fa-copy mx-3"></i>Duplicate</li>
                                                        <li @click="viewTask(Task.id,'edit','taskupdate')" class="hover-hand p-1"><i class="fa fa-pencil mx-3"></i>Task Status Update</li>
                                                        <li v-if="elements.currentUser.accountId == Task.assignedBy" v-b-modal.editTaskModal @click="viewTask(Task.id,'edit','')" class="hover-hand p-1"><i class="fa fa-pencil mx-3"></i>Edit</li>
                                                        <li v-if="elements.currentUser.accountId == Task.assignedBy" @click="dltTask(Task)" data-toggle="modal" data-target="#removeTask" class="hover-hand p-1"><i class="fa fa-trash mx-3"></i>Delete</li>
                                                    </ul>
                                                </h6>
                                            </span>
                                        </div>
                                        <div class="d-flex flex-row">
                                            <span class=" flex-fill mr-2" title="Task Type">{{ Task.taskType }}</span>
                                            <span class=" flex-fill pb-1" title="Priority">{{ Task.priority }}</span>
                                        </div>
                                        <div class="d-flex flex-row  pb-1">
                                            <span v-if="Task.startDate" class="completed-date flex-fill"><i title="Start date" class="ti-time mr-2"></i>{{ Task.startDate | formatDate}}</span>
                                            <span v-if="Task.dueDate > currentDate" class="due-date flex-fill"><i title="Due date" class="ti-time mr-2"></i>{{ Task.dueDate | formatDate}} </span>
                                            <span v-if="Task.dueDate <= currentDate" class="due-date flex-fill text-danger"><i title="Due date" class="ti-time mr-2"></i>{{ Task.dueDate | formatDate}}</span>
                                        </div>
                                        <div class="d-flex flex-row mt-1">
                                            <span class="flex-fill assignees">
                                                <span title="Assigned by" v-if="Task.assignedByName">{{ Task.assignedByName }}</span>
                                            </span>
                                            <span class="flex-fill assignees">
                                                <span title="Assigned to" v-if="Task.recruiterName">{{ Task.recruiterName }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div v-if="!getTaskByStageId(Stage.id).length" class="stage-card text-center">No Tasks found</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showTaskUpdate" class="card">
                    <TaskStatusUpdate :options="options" :taskInfo="editTask" :modeInfo="elements.field" @closeModal="closeModal" @setTaskCheckList="setTaskCheckList" @addTaskChecklist="addTaskChecklist" @addTaskComments="addTaskComments" @updateTaskComment="updateTaskComment" @deleteTaskComment="deleteTaskComment" @cancelTaskAttachments="cancelTaskAttachments" @addTaskAttachments="addTaskAttachments" @deleteTaskAttachment="deleteTaskAttachment" @updateTask="updateTask" @changeCMode="changeCMode" />
                    <!--  <aside class="main-sidebar right-sidemenu">
                            
                            <section class="sidebar">
                               
                                <ul class="sidebar-menu " data-widget="tree">
                                    <li ><a href="#"><img alt="images" src="@/assets/icons/web-design-hover.png" class="menuicon"> <span>Sourcing</span></a></li>
                                    <li ><a href="#"><img alt="images" src="@/assets/icons/user-silhouette-hover.png" class="menuicon"> <span>New Candidate</span></a></li>
                                    <li ><a href="#"><img alt="images" src="@/assets/icons/working-with-laptop-hover.png" class="menuicon"> <span>New Job</span></a></li>
                                </ul>
                            </section>
                        </aside>     -->
                </div>
            </div>
        </div>
        <Dialog id="removeTask" :onYes="deleteTask" :onNo="cancelDltTask" title="Delete Confirmation" message="Are you sure to remove this task?" />
        <b-modal ref="updateChecklist" id="updateChecklist" title="Update Confirmation" @close="getTaskChecklistByTaskId(editTask.id)" @ok="updateTaskCheckList" @cancel="getTaskChecklistByTaskId(editTask.id)">Are you sure to mark this item as {{ taskCheckListData.isCompleted?'completed':'not completed' }} ?</b-modal>
        <EditTaskModal :options="options" :editor="editor" :editTask="editTask" :modeInfo="elements.field" @setTaskCheckList="setTaskCheckList" @addTaskChecklist="addTaskChecklist" @addTaskComments="addTaskComments" @updateTaskComment="updateTaskComment" @deleteTaskComment="deleteTaskComment" @addTaskAttachments="addTaskAttachments" @cancelTaskAttachments="cancelTaskAttachments"  @deleteTaskAttachment="deleteTaskAttachment" @updateTask="updateTask" @changeCMode="changeCMode" />
        <AddProjectModal :options="options" :editor="editor" @getProjectList="getProjectList()" />
        <AddTaskGroupModal :options="options" :editor="editor" :project="selected.project.id?selected.project:{}" @getProjectList="getProjectList" @getSprintList="getSprintList" />
        <AddTaskModal :options="options" :editor="editor" :selectedInfo="selected" :taskData="editTask" @getSprintList="getSprintList" @loadTasks="loadTasks" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import Editor from '@tinymce/tinymce-vue';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import EditTaskModal from './blocks/tasks/EditTask';
import AddProjectModal from './blocks/tasks/AddProject';
import AddTaskGroupModal from './blocks/tasks/AddTaskGroup';
import TaskStatusUpdate from './blocks/tasks/TaskStatusUpdate';
import AddTaskModal from './blocks/tasks/AddTask';
export default {
    components: {
        Multiselect,
        Editor,
        EditTaskModal,
        AddTaskModal,
        AddProjectModal,
        AddTaskGroupModal,
        TaskStatusUpdate
    },
    computed: {
        taskid() {
            return this.$route.params.taskid;
        }
    },
    data() {
        return {
            isShow : false,
            taskId: "",
            elements: {
                currentUser: this.$store.getters.currentUser,
                toDelete: {
                    taskId: null
                },
                taskattachment: "",
                commentTag: "",
                checkListTag: "",
                field: {
                    mode: "view",
                    type: "",
                    id: null
                }
            },
            showTaskUpdate: false,
            recownerid: this.$store.getters.currentUser.recruitOwner,
            editor: {
                toolbarOptions: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
                initOptions: {
                    height: 200,
                    branding: false,
                    menubar: false,
                    plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists', 'advlist'],
                    relative_urls: false,
                    remove_script_host: false,
                    content_style: 'body { font-size:  13px; }'
                }
            },
            options: {
                projectList: [],
                sprintList: [],
                stageList: [],
                priorityList: [],
                statusList: [],
                taskTypeList: [],
                recruitersList: this.$store.getters.getRecruiterByCompany
            },
            list: {
                taskList: []
            },
            selected: {
                project: [],
                taskGroupId: null,
                stageId: null,
                mode: ""
            },
            editTask: {
                id: null,
                taskGroupId: null,
                taskCode: "",
                taskType: null,
                stageId: null,
                statusId: null,
                priorityId: null,
                relatedTo: null,
                progressPercentage: 5,
                title: "",
                description: "",
                assignedTo: null,
                userType: "recruiter",
                startDate: "",
                dueDate: "",
                completedDate: "",
                comments: [],
                taskComment: [],
               
                taskAttachment: [],
                taskCheckList: [],
                taskReference: "",
                assignedByName: ""

            },
            taskCheckListData: {
                id: null,
                title: "",
                isCompleted: false,
            },
            currentDate: "",
            attachments: [],
        }
    },
    validations: {
        elements: {
            commentTag: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(2000)
            },
            checkListTag: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(100)
            }
        }
    },
    mounted() {
        this.getProjectList();
        this.getTaskDropdownList();
        if (this.taskid != null) {
            this.viewTask(this.taskid, 'edit', 'taskupdate')
        } else {
            this.getTaskList();
        }
        this.addLogActivity('Listing');
    },
    methods: {
        showTaskGroup () {
            if(this.selected.project.length == 0){
                iziToast.info({ title: 'Info', message: this.$config.PlsSelProj, position: 'topRight' });
            }else{
                this.addLogActivity('Add Task Group');
                this.$root.$emit('bv::show::modal', 'createSprintModal')
            }      
        },
        checkEmpty(obj) {
            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        },
        getTaskList: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/taskresource/clientAccount/first/task')
                .then(response => {
                    let result = response.data;
                    if (result.status) {
                        if (result.taskGroupId != null) {
                            this.loadTasks(result.taskGroupId);
                            this.selected.project = result.project;
                            this.getSprintList(this.selected.project);
                            this.selected.taskGroupId = result.taskGroupId;
                        }
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        getProjectList: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/projectresource/list/project')
                .then(response => {
                    if (response.data.status) {
                        this.options.projectList = response.data.projectList;
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        getSprintList: function(project_info) {
            if (project_info.id != null) {
                axios.get(this.$store.getters.getAPIBasePath + '/projectresource/list/sprint/' + project_info.id)
                    .then(response => {
                        if (response.data.status) {
                            this.options.sprintList = response.data.taskGroupList;
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            }
        },
        getTaskDropdownList: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/task/dropdown/list')
                .then(response => {
                    if (response.data.status) {
                        this.options.stageList = response.data.taskStageList;
                        this.options.priorityList = response.data.taskPriorityList;
                        this.options.statusList = response.data.taskStatusList;
                        this.options.taskTypeList = response.data.taskTypeList;
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        loadTasks: function(sprintId) {
            this.selected.taskGroupId = sprintId;
            axios.get(this.$store.getters.getAPIBasePath + '/taskresource/list/task/' + sprintId)
                .then(response => {
                    if (response.data.status) {
                        this.list.taskList = response.data.taskList;
                        this.currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        getSprintName(sprintId) {
            let result_name = "";
            let taskGroupId = sprintId;
            this.options.sprintList.map(function(value, key) {
                if (value.id == taskGroupId)
                    result_name = value.name;
            });
            return result_name;
        },
        getTaskByStageId(stageId) {
            return this.list.taskList.filter(task => task.stageId == stageId);
        },
        getTaskCommentsByTaskId(taskId) {
            axios.get(this.$store.getters.getAPIBasePath + '/taskresource/list/task/comment/' + taskId)
                .then(response => {
                    if (response.data.status) {
                        this.editTask.taskComment = response.data.commentList;
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        getTaskAttachmentsByTaskId(taskId) {
            axios.get(this.$store.getters.getAPIBasePath + '/taskresource/list/task/attachement/' + taskId)
                .then(response => {
                    if (response.data.status) {
                        this.editTask.taskAttachment = response.data.attachmentList;
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        getTaskChecklistByTaskId(taskId) {
            axios.get(this.$store.getters.getAPIBasePath + '/taskresource/list/task/checklist/' + taskId)
                .then(response => {
                    if (response.data.status) {
                        this.editTask.taskCheckList = response.data.commentList;
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        addTaskChecklist: function(checklistData) {
            axios.post(this.$store.getters.getAPIBasePath + '/taskresource/add/task/checklist', checklistData)
                .then(response => {
                    if (response.data.status) {
                        iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                        this.getTaskChecklistByTaskId(this.editTask.id);
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                });
        },
        addTaskComments: function(commentData) {
            axios.post(this.$store.getters.getAPIBasePath + '/taskresource/add/task/comment', commentData)
                .then(response => {
                    if (response.data.status) {
                        iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                        this.getTaskCommentsByTaskId(this.editTask.id);
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                });
        },
        cancelTaskAttachments: function() {
           this.attachments = [];
        },
        addTaskAttachments: function(attachmentData) {
           this.attachments = attachmentData;
           console.log("attachments",this.attachments)
        },
        showTaskModal(stageId) {
            this.selected.stageId = stageId;
            this.$refs.createTaskModal.show();
        },
        viewTask: function(taskId, mode, model) {
            this.updateLogActivity();

            this.selected.mode = mode;
            axios.get(this.$store.getters.getAPIBasePath + '/taskresource/view/task/' + taskId)
                .then(response => {
                    if (response.data.status) {
                        this.editTask.id = mode == "edit" ? response.data.task.id : null;
                        this.editTask.taskGroupId = response.data.task.taskGroupId;
                        this.editTask.taskCode = response.data.task.taskCode;
                        this.selected.project = response.data.task.project;
                        if (this.selected.project != null)
                            this.getSprintList(this.selected.project);
                        this.selected.taskGroupId = this.editTask.taskGroupId;
                        this.editTask.taskAttachment = response.data.task.taskAttachment;
                        this.editTask.statusId = mode == "edit" ? response.data.task.statusId : null;
                        this.editTask.priorityId = response.data.task.priorityId;
                        this.editTask.title = response.data.task.title;
                        this.editTask.description = response.data.task.description;
                        this.editTask.progressPercentage = response.data.task.progressPercentage != null ? response.data.task.progressPercentage : 0;
                        this.editTask.assignedTo = mode == "edit" ? response.data.task.assignedTo : null;
                        this.editTask.userType = "recruiter";
                        this.editTask.taskType = response.data.task.taskType;
                        this.editTask.taskReference = response.data.task.taskReference;
                        if (mode == 'duplicate') {
                            this.addLogActivity('Task Duplicate');
                            this.editTask.startDate = new Date().toISOString().substr(0, 10);
                            this.editTask.dueDate = new Date().toISOString().substr(0, 10);
                        } else {
                            this.editTask.startDate = moment(String(response.data.task.startDate)).format('YYYY-MM-DD');
                            this.editTask.dueDate = moment(String(response.data.task.dueDate)).format('YYYY-MM-DD');

                        }
                        this.editTask.assignedByName = response.data.task.assignedByName;
                        this.editTask.completedDate = response.data.task.completedDate != null ? moment(String(response.data.task.completedDate)).format('YYYY-MM-DD') : "";
                        if (mode == 'edit') {
                            this.addLogActivity('Edit');
                            this.getTaskCommentsByTaskId(this.editTask.id);
                            // this.getTaskAttachmentsByTaskId(this.editTask.id);
                        }
                        if (mode == 'view' && mode == 'edit') {
                        this.getTaskAttachmentsByTaskId(this.editTask.id);
                        }
                        this.getTaskChecklistByTaskId(taskId);

                        if (model == 'taskupdate') {
                            this.addLogActivity('Task Status Update');
                            this.showTaskUpdate = true;
                        }
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        deleteTask: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/taskresource/delete/task/' + this.elements.toDelete.taskId)
                .then(response => {
                    if (response.data.status == "OK") {
                        iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                        this.loadTasks(this.selected.taskGroupId);
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                });
        },
        updateTaskCheckList: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/taskresource/update/task/checklist', this.taskCheckListData)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                        this.getTaskChecklistByTaskId(this.editTask.id);
                        this.$refs.updateChecklist.hide();
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                });
        },
        setTaskCheckList: function(taskCheckList) {
            this.taskCheckListData.id = taskCheckList.id;
            this.taskCheckListData.title = taskCheckList.title;
            this.taskCheckListData.isCompleted = taskCheckList.isCompleted;
            this.$refs.updateChecklist.show();
        },
        changeCMode: function(mode, type = "", id = null) {
            this.elements.field.mode = mode;
            this.elements.field.type = type;
            this.elements.field.id = id;
        },
        updateTaskComment: function(taskComment) {
            let taskComments = { id: taskComment.id, comment: taskComment.comment };
            axios.post(this.$store.getters.getAPIBasePath + '/taskresource/update/task/comment', taskComment)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                        this.getTaskCommentsByTaskId(this.editTask.id);
                        this.changeCMode('view');
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                });
        },
        deleteTaskComment: function(commentId) {
            axios.delete(this.$store.getters.getAPIBasePath + '/taskresource/delete/task/comment/' + commentId)
                .then(response => {
                    if (response.data.status == "OK") {
                        iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                        this.getTaskCommentsByTaskId(this.editTask.id);
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                });
        },
        deleteTaskAttachment: function(attachmentId,index) {
            axios.delete(this.$store.getters.getAPIBasePath + '/taskresource/delete/task/attachment/' + attachmentId)
                .then(response => {
                    if (response.data.status == "OK") {
                        iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                        this.editTask.taskAttachment.splice(index, 1);
                        // this.getTaskAttachmentsByTaskId(this.editTask.id);
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                });
        },
        updateTask: function(taskData) {
            axios.post(this.$store.getters.getAPIBasePath + '/taskresource/update/task', taskData)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                         let taskId = taskData.id;
                         if (this.attachments != null && this.attachments.length > 0) {
                                let formData = new FormData();

                                for (const row of Object.keys(this.attachments)) {
                                    formData.append('files', this.attachments[row]);
                                }
                    
                                formData.append('id', taskId);

                                axios.post(this.$store.getters.getAPIBasePath + '/taskresource/uploadattachment/multiple', formData, {
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    })
                                    .then(response => {
                                        let result = response.data;
                                        this.$root.$emit('refreshAttach')
                                        console.log("result",result)
                                        // if (result.status == 'ACCEPTED') {
                                           
                                        //     iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                                        // } else {
                                        //     iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                                        // }
                                    })
                                    .catch(error => {
                                        this.unAuthorized(error);
                                        console.log(error.data);
                                    });
                            }
                        iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                        this.refreshTaskView();
                        this.loadTasks(this.selected.taskGroupId);
                        this.showTaskUpdate = false;
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    // iziToast.error({ title: 'Error', message: 'Something went wrong. Please try again', position: 'topRight' });
                });
        },
        refreshTaskView: function() {
            this.editTask.id = null;
            this.editTask.taskGroupId = null;
            this.editTask.stageId = null;
            this.editTask.statusId = null;
            this.editTask.priorityId = null;
            this.editTask.relatedTo = null;
            this.editTask.progressPercentage = 5;
            this.editTask.title = "";
            this.editTask.description = "";
            this.editTask.assignedTo = null;
            this.editTask.userType = "recruiter";
            this.editTask.startDate = "";
            this.editTask.dueDate = "";
            this.editTask.completedDate = "";
            this.editTask.comments = [];
            this.editTask.taskComment = [];
          
            this.editTask.taskAttachment = [];
            this.editTask.taskCheckList = [];
            this.attachments = [];
        },

        closeModal: function() {
            this.showTaskUpdate = false;
            if (this.taskid != null) {
                this.loadTasks(this.editTask.taskGroupId);

            }
        },
        addLogActivity: function(action) {
            this.updateLogActivity();
            this.logActivityData = {
                entityName: 'Task',
                actionName: action,
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', this.logActivityData)
                .then(response => {})
                .catch(error => {});
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
        createTask: function(){
            this.selected.mode = '';
            this.addLogActivity('Create Task');
        },
        dltTask: function(task){
            this.elements.toDelete.taskId=task.id;
            this.addLogActivity('Delete Task');
        },
        cancelDltTask: function(){
            this.elements.toDelete.taskId=null;
            this.updateLogActivity();
        }
    }
}

</script>
