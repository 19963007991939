<template>
    <div id="main" class="mt-15 d-height">
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
            <div class="container-fluid" id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right mb-0">
                                <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :class="isExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
                            </ul>
                        </div>
                    </div>
                    <form id="main-search" :class="isExpand?'d-block':'d-none'"  v-on:submit.prevent="">
                        <div class="row form-group ten-columns">
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" v-on:keyup.enter="findUsers" v-model="search.params.name" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" v-on:keyup.enter="findUsers" v-model="search.params.email" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr pl-0">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label style="visibility: hidden;">End</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12 float-right">
                                        <button type="button" class="btn btn-primary float-left" @click="findUsers" style="margin-left: 10px;">Find</button>
                                        <button type="button" class="btn btn-primary float-left" @click="resetFilter()" style="margin-left: 10px;">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="container-fluid" id="main-data">
                <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-6 col-sm-12 col-12">
                            <h4 class="page-title">Deactivated Users
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                    <div class="dropdown">
                                        <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                        <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                            <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                        </div>
                                    </div>
                                </span>
                            </h4>
                        </div>
                    </div>
                    <div class="row pt-20 mt-20">
                        <div class="col-sm-12">
                            <table class="table table-borderless all-table">
                                <thead>
                                    <tr>
                                        <th v-if="false">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input"  type="checkbox" id="rowchckboxall" value="option1">
                                                <label class="form-check-label" for="rowchckboxall"></label>
                                            </div>
                                        </th>              
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Timezone</th>
                                        <th>Candidate</th>
                                        <th>Interviewer</th>
                                        <th>Referral</th>
                                        <th>Recruiter</th>
                                        <th>Guest</th>
                                        <th>Created Date</th>
                                        <th>Deactivated Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="!deactivateUserList.length && elements.loadingUsers == true">
                                        <td class="text-center" colspan="100%">No Users found</td>
                                    </tr>
                                    <tr v-for="user in deactivateUserList">
                                        <td v-if="false">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" :id="'rowchckbox'+user.id" v-model="panelMem" :value="user.id" checked="">
                                                <label class="form-check-label" :for="'rowchckbox'+user.id"></label>
                                            </div>
                                        </td>
                                        <td>
                                            {{user.name}}
                                        <!-- <a href="Javascript:void(0);" @click="addLogActivity('View User')"> <router-link :to="{ name:'viewuser', params:{userid: user.id}}">{{user.name}}</router-link></a> -->
                                        </td>
                                        <td>{{user.email}}</td>
                                        <td>{{user.timezone}}</td>
                                        <td>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" disabled type="checkbox" :id="'cand-chckbox-'+user.id" checked=""  v-model="user.candidateId" :value="user.candidateId" />                                   
                                                <label class="form-check-label" :for="'cand-chckbox-'+user.id"></label>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" disabled type="checkbox" :id="'inv-chckbox-'+user.id" checked=""  v-model="user.interviewerId" :value="user.interviewerId" />                                   
                                                <label class="form-check-label" :for="'inv-chckbox-'+user.id"></label>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" disabled type="checkbox" :id="'ref-chckbox-'+user.id" checked=""  v-model="user.referralId" :value="user.referralId" />                                   
                                                <label class="form-check-label" :for="'ref-chckbox-'+user.id"></label>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" disabled type="checkbox" :id="'rec-chckbox-'+user.id" checked=""  v-model="user.recruiterId" :value="user.recruiterId" />                                   
                                                <label class="form-check-label" :for="'rec-chckbox-'+user.id"></label>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" disabled type="checkbox" :id="'rec-chckbox-'+user.id" checked=""  v-model="user.guestId" :value="user.guestId" />                                   
                                                <label class="form-check-label" :for="'rec-chckbox-'+user.id"></label>
                                            </div>
                                        </td>
                                        <td>
                                            {{user.createdAt | formatDate}}
                                        </td>
                                        <td>
                                            {{user.modifiedUtc | formatDate}}
                                        </td>
                                        <td>
                                            <ul class="navbar-nav">
                                                <li class="nav-item dropdown">
                                                    <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="javascript:" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">                                   
                                                        <li>                                                     
                                                            <a class="dropdown-item" href="javascript:" @click="ActivateUser(user.id)" data-toggle="modal" data-target="#activate"><span class="ti-check pr-2"></span> Activate</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <b-pagination v-if="deactivateUserList.length!=0" size="md" class='pull-right'  :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadUserList()"></b-pagination>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div>
                    <img alt="images" class="loader-img-height"  src="/images/loading.gif"> 
                </div>                   
            </template> 
        </b-overlay>        
        <Dialog id="activate" :onYes="Activateuser" :returnParams="activateUserParam" title="Activate Confirmation" message="Are you sure to activate?" />
        <EditUserDialog ref="editUserRef" @refresh-user="loadUserList"  :userData="userInfo" /> 
    </div>
</template>
<script>
import iziToast from 'izitoast';
import EditUserDialog  from '../blocks/user/EditUser';
export default {
    components: {
        EditUserDialog
    },
    data() {
        return {
            showOverlay:false,
            search: {
                params: {
                    name: '',
                    email: '',                  
                    pageNo:"",
                    limit:"",
                },
            },
            deactivateUserList: [],
            isExpand: false,
            searchlimit: this.$store.state.searchlimit,
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            panelMem: [],
            activateUserParam: {
                index: -1,
                id: 0
            },
            elements: {
                loadingUsers: false,
            },
            userInfo:{
                id:'',
                firstName:'',
                lastName:'',
                email:'',
                timezone:'',
                recruiterId:'',
                interviewerId:'',
                referralId:'',
                candidateId:''
            }
        }
    },
    computed: { },
    mounted() {
        this.$store.commit('refreshPagination');
        this.loadUserList();
        this.$root.$on('deactivatedusers', () => {
            this.loadUserList();
        })
    },
    methods: {
        async loadUserList() {
            this.showOverlay=true;
            await this.getUserList();
            this.showOverlay=false;
        },
        getUserList() {
            this.showOverlay=true;
            this.search.params.pageNo = this.pagination.currentPage;
            this.search.params.limit = this.pagination.rowsPerPage;
            return axios.post(this.$store.getters.getAPIBasePath + '/userresource/deactivate/userlist', this.search.params)
                .then(response => {
                    let result = response.data;
                    this.deactivateUserList = result.recordinfo;   
                    console.log(this.deactivateUserList);
                    this.pagination.totalRecords = result.totalrecords;
                    this.pagination.currentPage = result.currentpage;
                    this.elements.loadingUsers = true;
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                });
        },
        filterInterviewers() {
            this.loadUserList();
        },
        resetFilter() {
             this.pagination.currentPage = 1;
            this.search.params.name = "";
            this.search.params.email = "";
            this.loadUserList();
        },
        changeLimit(newlimit) {
            this.pagination.currentPage = 1;
            this.pagination.rowsPerPage = newlimit;
            this.loadUserList();
        },
        findUsers() {
            this.pagination.currentPage = 1;
            this.loadUserList();
        },
        addLogActivity: function(action) {
            this.updateLogActivity();
            let logActivityData = {
                entityName: 'AccountUser',
                actionName: action,
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', logActivityData)
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
        ActivateUser(userId) {
            this.activateUserParam.id = userId;
        },
        Activateuser(){
            var userId = this.activateUserParam.id;
            axios.post(this.$store.getters.getAPIBasePath + '/userresource/change/status/' + userId)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: this.$config.UserActSuc,
                            position: 'topRight'
                        });
                        this.$root.$emit('Listusers')
                        this.loadUserList();
                    }
                })
                .catch(error => {
                    console.log(error.data);
                    this.unAuthorized(error);
                });
        },
    }
}

</script>
