<template>
    <div class="kpis reports text-left">
        <div class="row">
            <div class="col-sm-12 p-10">
                <div id="recruiter-settings" class="recruiter settings mt-10">
                    <div class="container-fluid" id="quick-search">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="text-center">Candidate Details</h5>
                            </div>
                        </div>
                        <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-6">
                                    <h4 class="page-title">Filter</h4>
                                </div>
                                <div class="col-md-6 col-sm-6 col-6">
                                    <ul class="ul-horizontal box-tools text-right mb-0">
                                        <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :title="isExpand?'Hide':'Expand'" :class="isExpand?'ti-angle-up':'ti-angle-down'" class="hover-hand"></span></a></li>
                                        <li><i class="ti-close hover-hand px-2" @click="$emit('setSubTab',0);$emit('updateLogActivity');" title="Close"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <form id="main-search" :class="isExpand?'d-block':'d-none'">
                                <div class="row form-group ten-columns">
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Name</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input class="form-control" type="text" v-on:keyup.enter="findCandidates()" v-model="search.params.firstName" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Email</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input class="form-control" type="text" v-on:keyup.enter="findCandidates()" v-model="search.params.email" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Location</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input class="form-control" type="text" v-on:keyup.enter="findCandidates()" v-model="search.params.location" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Technology</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <select class="form-control" v-model="search.params.technologyId">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in technologies" :value="info.id">{{info.technology}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Recruiter</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <select class="form-control" v-model="search.params.recruiterId">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in recruiterlist" :value="info.id">{{info.recruiterName}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group ten-columns">
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Start Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker :disabledDates="disabledStartDates" placeholder="Start Date" name="startDate" value="" id="startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.startDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>End Date</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <datepicker :disabledDates="disabledendDates" placeholder="End Date" name="endDate" value="" id="endDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="search.params.endDate"></datepicker>
                                                    <span class="calendar-addon"><span class="ti-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Position</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input class="form-control" type="text" v-on:keyup.enter="findCandidates()" v-model="search.params.position" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Industry</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <select class="form-control" v-model="search.params.industryId">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in industrylist" :value="info.id">{{info.industryName}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Status</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <select class="form-control" v-model="search.params.status">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in candStatusList" :value="info.id">{{info.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label style="visibility: hidden;">Technology</label>
                                            </div>
                                            <div class="col-md-12">
                                                <button type="button" @click="resetCandidatesSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                                <button type="button" @click="findCandidates" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Go</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="load-indicator"></div>
                        </div>
                    </div>
                    <div class="container-fluid" id="main-data">
                        <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                            <div class="row" style="margin-bottom: 15px;">
                                <div class="col-md-6 col-sm-12 col-12">
                                    <h4 class="page-title">Candidates
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                        <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                            <div class="dropdown">
                                                <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                                <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                                    <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                                </div>
                                            </div>
                                        </span>
                                    </h4>
                                </div>
                                <div class="col-md-6 d-none d-sm-none d-md-block">
                                    <span class="float-right" @click="exportCandidates()"><i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt=""></i></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table class="table table-borderless all-table">
                                            <thead>
                                                <tr>
                                                    <th class="" scope="col">Sl.No</th>
                                                    <th class="" scope="col">Candidate Code</th>
                                                    <th class="" scope="col">Name</th>
                                                    <th class="" scope="col">Position</th>
                                                    <th v-if="coldisplay.recruiter" class="recruiter" scope="col">Experience</th>
                                                    <th v-if="coldisplay.technology" class="technology" scope="col">Technology/Skills</th>                                                   
                                                    <th  class="location" scope="col">Location</th>
                                                    <th  class="phone" scope="col">Phone</th>
                                                    <th  class="email" scope="col">Email</th>
                                                    <th class="recruiter" scope="col">Recruiter</th>
                                                    <th class="status" scope="col">Status</th>
                                                     <th class="status" scope="col">Created Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="candidateslist.length==0">
                                                    <td colspan="12" align="center">No Candidates found</td>
                                                </tr>
                                                <tr v-for="(candidateinfo, index) in candidateslist">
                                                    <td>{{((pagination.currentPage-1) * (pagination.rowsPerPage)) + (index+1)}}</td>
                                                    <td>{{candidateinfo.candidateCode}}</td>
                                                    <td>
                                                        {{candidateinfo.candidateName}}
                                                    </td>
                                                    <td>{{ candidateinfo.position }}</td>
                                                    <td v-if="coldisplay.recruiter" class="recruiter">{{ candidateinfo.experience }}</td>
                                                    <td v-if="coldisplay.technology" :title="candidateinfo.technology" v-html="gettechsubstring(candidateinfo.technology)" class="specialityunit"></td>

                                                    <td class="location">{{ candidateinfo.location }}</td>
                                                    <td  class="phone">{{ candidateinfo.mobile }}</td>
                                                    <td  class="email">{{ candidateinfo.email }}</td>
                                                    <td class="recruiter">{{ candidateinfo.recruiterName }}</td>
                                                    <td class="status">{{ candidateinfo.candidateStatus }}</td>
                                                    <td class="status">{{ candidateinfo.createdAt  | formatDate}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <b-pagination v-if="candidateslist.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadCandidates()"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { mapState } from 'vuex';
export default {
    props: {
        candContactAccess: [String, Number]
    },
    data() {
        return {

            isExpand: false,
            candidateslist: [],
            technologies: this.$store.getters.getTechOptions,
            industrylist: this.$store.getters.getIndustries,
            recruiterlist: this.$store.getters.getRecruiterList,
            candStatusList: this.$store.getters.getCandStatusReport,
            searchlimit: this.$store.state.searchlimit,
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            elements: {
                showadvancedsearch: false,
                submitted: null,
                toolbar: 'undo redo formatselect| bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
                initObj: {
                    menubar: false,
                    height: 300,
                    branding: false,
                }
            },

            search: {
                params: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    location: "",
                    technologyId: "",
                    recruiterId: "",
                    position: "",
                    industryId: "",
                    status: "",
                    startDate: "",
                    endDate: "",
                    candidateContactAccess: localStorage.getItem('candidateContactAccess'),
                },

                recruiters: [],
                skillset: [],
                currentstate: '',
                minexp: '',
                maxexp: '',
                region: '',
                referal: '',
                country: '',
                jobtype: '',
                industry: ''
            },
            coldisplay: {
                recruiter: true,
                title: true,
                technology: true,
                currentcity: true,
                state: true
            },
            form: {
                title: "",
                recruiter_id: this.$store.getters.currentUser.accountId,
                user_id: null,
                user_type: "candidate",
                communication_type: "",
                communication_mode: "",
                notes: ""
            },
            notes: "",
            disabledStartDates: {
                from: new Date()
            
            },
             disabledendDates:{
                from: new Date(),
                to: ''
            }
        }
    },
    computed: mapState(['globalSearchValue']),
    watch: {
        globalSearchValue(newValue, oldValue) {
            this.search.params.firstName = newValue;
            this.loadCandidates();
        },
         'search.params.startDate': {
            handler: function(value) {
                this.disabledendDates.to = new Date(value);
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
        this.$store.commit('refreshPagination');
        this.loadDate();
        this.loadCandidates();    
        this.$emit('updateLogActivity'); 
        this.$emit('addLogActivity','Candidate Details Report');   
    },
    methods: {        
        resetCandidatesSearch() {
            this.search.params = {
                firstName: "",
                lastName: "",
                email: "",
                location: "",
                technologyId: "",
                recruiterId: "",
                position: "",
                industryId: "",
                status: "",
                startDate: "",
                endDate: "",               
            };
            this.loadDate();
            this.disabledStartDates.from =  new Date();
            this.disabledendDates.from =  new Date();
            this.loadCandidates();
        },
        loadDate() {
            let date = new Date();
            this.search.params.startDate = date;//new Date(date.getFullYear(), date.getMonth(), 1);    
            this.search.params.endDate = date;        
        },
        loadCandidates() {
            if (this.search.params.startDate > this.search.params.endDate) {
                iziToast.error({
                    title: 'Error',
                    message: this.$config.DateMismatchErr,
                    position: 'topRight'
                });
                return;
            }
            axios.post(this.$store.getters.getAPIBasePath + '/reportsresource/candidatereport?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    this.candidateslist = result.candidates;
                    this.pagination.totalRecords = result.pagination[0].total;
                    this.pagination.currentPage = result.pagination[0].currentPage;
                })
                .catch(error => {
                    console.log(error);
                });

        },
        updateSearchRecruiters: function(value) {
            this.search.recruiters = value;
        },
        updateSearchSkillset: function(value) {
            this.search.skillset = value;
        },
        changeLimit(newlimit) {
             this.pagination.currentPage = 1;
            this.pagination.rowsPerPage = newlimit;
            this.loadCandidates();
        },
        exportCandidates() {
            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/export/candidates?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "Candidates" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    this.reports = [];
                });

        },
        findCandidates() {
            this.pagination.currentPage = 1;
            this.loadCandidates();
        },
        gettechsubstring(technology) {
            if (technology != null && technology.length > 10) {
                return technology.substring(0, 10) + "...";
            } else
                return technology;
        }
    }
}

</script>
