<template>
    <div id="content_Personal_Info">
        <div class="card">
            <div class="card-header p-2">Contact Information</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col col1 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Mobile</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <PhoneNumber
                                        ref="phoneNumber"
                                        :value="ContactInfo.mobileNo"
                                        :mandatory="phoneMandatory"
                                        @phone-input="phoneControlInput"
                                    />
                                    <!-- <div
                                        v-if="false"
                                        class="input-group required-field-block"
                                    >
                                        <select
                                            class="form-control col-sm-3"
                                            v-model="ContactInfo.countryCode"
                                        >
                                            <option value="+1">+1</option>
                                            <option value="+91">+91</option>
                                        </select>
                                        <input-mask
                                            class="form-control"
                                            v-model="ContactInfo.mobile"
                                            mask="(999)-999-9999"
                                            :alwaysShowMask="false"
                                            maskChar="_"
                                        ></input-mask>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col2 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Alternate Mobile</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <PhoneNumber
                                        ref="altPhoneNumber"
                                        :value="ContactInfo.altMobileNo"
                                        @phone-input="altPhoneControlInput"
                                    />
                                    <!-- <div
                                        v-if="false"
                                        class="input-group required-field-block"
                                    >
                                        <select
                                            class="form-control col-sm-3"
                                            v-model="ContactInfo.altcountryCode"
                                        >
                                            <option value="+1">+1</option>
                                            <option value="+91">+91</option>
                                        </select>
                                        <input-mask
                                            class="form-control"
                                            v-model="ContactInfo.altMobile"
                                            mask="(999)-999-9999"
                                            :alwaysShowMask="false"
                                            maskChar="_"
                                        ></input-mask>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col3 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Address Line #1</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="25"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.addressLine1"
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col col4 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Address Line #2</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="25"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.addressLine2"
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ten-columns">
                        <div class="col col1 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Country</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <select
                                            class="form-control"
                                            v-model="ContactInfo.countryId"
                                        >
                                            <option value="" selected="selected">Select</option>
                                            <option
                                                v-for="Country in Countries"
                                                v-bind:key="Country.country_Id"
                                                :value="Country.country_Id"
                                            >
                                                {{ Country.name }}
                                            </option>
                                        </select>
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col col2 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>State</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <select
                                            class="form-control"
                                            v-if="ContactInfo.countryId == 'US'"
                                            v-model="ContactInfo.stateName"
                                        >
                                            <option value="">State</option>
                                            <option
                                                v-for="State in USStates"
                                                :value="State.stateName"
                                            >
                                                {{ State.stateName }}
                                            </option>
                                        </select>
                                        <input
                                            v-if="ContactInfo.countryId != 'US'"
                                            type="text"
                                            maxlength="25"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.stateName"
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col col3 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>City</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="25"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.cityName"
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col col4 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Zip Code</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="25"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.pincode"
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-header p-2">Social Connect</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col col1 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Skype</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="100"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.skypeId"
                                            v-bind:style="[
                                                isValid(ContactInfo.skypeId)
                                                    ? {
                                                          'text-decoration':
                                                              'underline',
                                                          color: 'blue',
                                                          cursor: 'pointer',
                                                      }
                                                    : '',
                                            ]"
                                            @click="
                                                redirectlink(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                    <!-- <label v-if="$v.ContactInfo.skypeId.$error && !$v.ContactInfo.skypeId.url" class="text-danger">Skype is not valid</label> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col2 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Facebook</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="100"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.facebookUrl"
                                            v-bind:style="[
                                                isValid(ContactInfo.facebookUrl)
                                                    ? {
                                                          'text-decoration':
                                                              'underline',
                                                          color: 'blue',
                                                          cursor: 'pointer',
                                                      }
                                                    : '',
                                            ]"
                                            @click="
                                                redirectlink(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                    <!-- <label v-if="$v.ContactInfo.facebookUrl.$error && !$v.ContactInfo.facebookUrl.url" class="text-danger">Facebook is not valid</label> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col3 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Twitter</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="100"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.twitterUrl"
                                            v-bind:style="[
                                                isValid(ContactInfo.twitterUrl)
                                                    ? {
                                                          'text-decoration':
                                                              'underline',
                                                          color: 'blue',
                                                          cursor: 'pointer',
                                                      }
                                                    : '',
                                            ]"
                                            @click="
                                                redirectlink(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                    <!-- <label v-if="$v.ContactInfo.twitterUrl.$error && !$v.ContactInfo.twitterUrl.url" class="text-danger">Twitter is not valid</label> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col4 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Linkedin</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="100"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.linkedinUrl"
                                            v-bind:style="[
                                                isValid(ContactInfo.linkedinUrl)
                                                    ? {
                                                          'text-decoration':
                                                              'underline',
                                                          color: 'blue',
                                                          cursor: 'pointer',
                                                      }
                                                    : '',
                                            ]"
                                            @click="
                                                redirectlink(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                    <!-- <label v-if="$v.ContactInfo.linkedinUrl.$error && !$v.ContactInfo.linkedinUrl.url" class="text-danger">Linkedin is not valid</label> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ten-columns">
                        <div class="col col1 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Hangout</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="100"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.hangoutId"
                                            v-bind:style="[
                                                isValid(ContactInfo.hangoutId)
                                                    ? {
                                                          'text-decoration':
                                                              'underline',
                                                          color: 'blue',
                                                          cursor: 'pointer',
                                                      }
                                                    : '',
                                            ]"
                                            @click="
                                                redirectlink(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                    <!-- <label v-if="$v.ContactInfo.hangoutId.$error && !$v.ContactInfo.hangoutId.url" class="text-danger">Hangout is not valid</label> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col2 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Website</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="100"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.websiteUrl"
                                            v-bind:style="[
                                                isValid(ContactInfo.websiteUrl)
                                                    ? {
                                                          'text-decoration':
                                                              'underline',
                                                          color: 'blue',
                                                          cursor: 'pointer',
                                                      }
                                                    : '',
                                            ]"
                                            @click="
                                                redirectlink(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                    <!-- <label v-if="$v.ContactInfo.websiteUrl.$error && !$v.ContactInfo.websiteUrl.url" class="text-danger">Website is not valid</label> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col3 mb-10-mr"></div>
                        <div class="col col4 mb-10-mr"></div>
                    </div>
                </div>
            </div>
            <div class="card-header p-2">
                <a
                    href="Javascript:void(0);"
                    @click="saveContactInfo"
                    class="btn btn-primary float-right"
                    >Save</a
                >
            </div>
        </div>
    </div>
</template>
<script>
import { url, helpers } from "vuelidate/lib/validators";
import iziToast from "izitoast";

const usPhone = helpers.regex("usPhone", /^\(\d{3}\)\s\d{3}-\d{4}$/);
var expression =
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
var regex = new RegExp(expression);
export default {
    props: {
        ContactInfo: {},
        profileId: Number,
    },
    data() {
        return {
            Countries: this.$store.getters.getCountries,
            USStates: this.$store.getters.getUSStates,
            phoneMandatory: true,
        };
    },
    mounted() {},
    methods: {
        redirectlink(value) {
            if (value) {
                window.open("https://" + value, "_blank");
            }
        },
        isValid(data) {
            if (data != null && data.match(regex)) {
                return true;
            } else {
                return false;
            }
        },
        saveContactInfo: function () {
            /*   if (this.ContactInfo.mobile != null && this.ContactInfo.mobile != '') {
                this.ContactInfo.mobileNo = this.ContactInfo.countryCode + " " + this.ContactInfo.mobile;                
            }

            if (this.ContactInfo.altMobile != null && this.ContactInfo.altMobile != '') {
                this.ContactInfo.altMobileNo = this.ContactInfo.altcountryCode + " " + this.ContactInfo.altMobile;                
            } */
            if (
                this.$refs.phoneNumber.validatePhoneNumber() &&
                this.$refs.altPhoneNumber.validatePhoneNumber()
            ) {
                let contactInfo = { referralContact: this.ContactInfo };

                axios
                    .post(
                        this.$store.getters.getAPIBasePath +
                            "/referralresource/update/referral/" +
                            this.profileId,
                        contactInfo
                    )
                    .then((response) => {
                        iziToast.success({
                            title: "Success",
                            message: this.$config.IntConInfoUpSuc,
                            position: "topRight",
                        });
                        this.$emit("loadReferral");
                    })
                    .catch((error) => {
                        console.log(error);
                        iziToast.error({
                            title: "Error Occured",
                            message: this.$config.IntConInfoUpErr,
                            position: "topRight",
                        });
                    });
            }
        },
        phoneControlInput: function (value) {
            console.log(value);
            this.ContactInfo.mobileNo = value;
        },
        altPhoneControlInput: function (value) {
            console.log(value);
            this.ContactInfo.altMobileNo = value;
        },
    },
};
</script>
