<template>
    <div class="account-info">
        <div class="card">
            <div class="card-header p-2">Account Information</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row col-md-12 my-4">
                        <div class="col-md-4">
                            <div class="table-responsive">
                                <table class="table table-striped table-borderless s_table compare-table">
                                    <tbody>
                                        <tr>
                                            <td width="50%"><strong>Total Candidates</strong></td>
                                            <td width="50%" class="text-center">{{ calcData.totalCandidates }}</td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong>Total Interviewers</strong></td>
                                            <td width="50%" class="text-center">{{ calcData.totalInterviewers }}</td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong>Total Clients</strong></td>
                                            <td width="50%" class="text-center">{{ calcData.totalClients }}</td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong>Total Referrals</strong></td>
                                            <td width="50%" class="text-center">{{ calcData.totalReferrals }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        profileId: Number
    },
    data() {
        return {
            calcData: {
                totalCandidates: '',
                totalInterviewers: '',
                totalClients: '',
                totalReferrals: ''
            }
        }
    },
    mounted() {
        this.loadCount();
    },
    methods: {
        loadCount: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/referralresource/referral/info/count/' + this.profileId)
                .then(response => {
                    console.log(response.data);
                    this.calcData.totalInterviewers = response.data.interviewers;
                    this.calcData.totalCandidates = response.data.candidates;
                    this.calcData.totalClients = response.data.clients;
                    this.calcData.totalReferrals = response.data.referrals;
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
