/* eslint-disable quotes */
/* eslint-disable comma-spacing */
/* eslint-disable key-spacing */
/* eslint-disable no-tabs */
/* eslint-disable indent */
export default {
    state: {
        userdata: {},
        globalSearchValue: "",
        searchlimit: [5, 10, 25, 50, 100],
        selectedlimit: 10,
        paginationconfig: {
            currentpage: 1,
            totalpages: 2,
            fromrecords: 1,
            torecords: 1,
            totalrecords: 1,
            startpage: 1,
            endpage: 1,
            maxpagepins: 6
        },
        countries: [],
        educountries: [],
        visaoptions: [],
        techoptions: [],
        accountTypeOptions: [],
        jobExpTypeOptions: [],
        salarymodes: [],
        recruiters: [],
        industryoptions: [],
        shiftoptions: [],
        usstateslist: [],
        clientcontacttypelist: [],
        interviewerScopeList: [],
        candidateType: [],
        apibasepath: process.env.VUE_APP_BASEURL,
        vuebasepath: process.env.VUE_APP_URL,
        webappurl: process.env.VUE_APP_MAIN_URL,
        local_storage_name: process.env.VUE_APP_LOCALSTORAGE_NAME,
        google_client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        google_redirect_url: process.env.VUE_APP_GOOGLE_REDIRECT_URL,
        facebook_client_id: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
        facebook_redirect_url: process.env.VUE_APP_FACEBOOK_REDIRECT_URL,
        linkedin_client_id: process.env.VUE_APP_LINKEDIN_CLIENT_ID,
        linked_redirect_url: process.env.VUE_APP_LINKEDIN_REDIRECT_URL,
        configloaded: 0,
        dateformat: 'DD-MMM-YYYY',
        timeformat: 'hh:mm a',
        datetimeformat: 'DD-MMM-YYYY  hh:mm A',
        datetimezoneformat: "YYYY-MM-DD HH:mm:ss",
        default_redirects: {
            interviewer: '/interviewer/dashboard',
            recruiteradmin: '/candidates/recdashboard',
            recruiter: '/candidates/dashboard',
            candidate: '/candidate/dashboard',
            // admin:'/admin/candidates'
            admin: '/admin/dashboard',
            referral: '/referral/dashboard',
            superadmin: '/admin/sadashboard',
            guest: '/guest/dashboard',
            corpadmin: '/candidates/corpdashboard'
        },
        interviewStatus: [],
        jobStatus: [],
        interviewType: [],
        timeZones: [],
        languages: [],
        clients: [],
        workType: [],
        payFrequencyList: [],
        recPlanId: null,
        globalJobId: null,
        shortlistedCandidateStatus: [],
        access_key: null,
        number: null,
        candidateScopeList: [],
        jobScopeList: [],
        templateTypesList: [],
        socialMediaTemplateTypesList: [],
        emailTemplateScope: [],
        weblinks: [],
        tagNames: [],
        sourcingChannel: [],
        nameValueList: [],
        additionalFieldsType: { xNumber: 1, xText: 2, xYesNo: 3, xDate: 4 },
        recruiterlist: [],
        candidatesStatusList: [],
        candStatusReport: [],
        projectStatusList: [],
        taskGroupStatusList: [],
        notificationList: {
            taskId: 0,
        },
        recruiterByCompany: [],
        settingsQualification: [],
        settingsJobDocument: [],
        settingsWorkAuthorization: [],
        relocationStatus: [],
        ttClients: [],
        candContactAccess: [],
        clientCompanyList: [],
        currencies: [],
        routeName: ['listcandidates', 'listjobs', 'interviewers', 'listreferrals', 'listrecruiter', 'viewclients', 'candidatejobs', 'candidateinterviews', 'referraljoblist', 'referralcandidates', 'referralinterviewers', 'listclientaccount', 'adminlistcandidate', 'adminlistjobs', 'adminlistclients', 'adminlistinterviewers', 'adminlistrecruiters', 'adminlistuser', 'interviewerrequests', 'guestcandidates', 'guestjoblist']
    },
    getters: {
        isConfigLoaded(state) {
            if (state.configloaded == 1) {
                return true;
            }
            return false;
        },
        currentUser(state) {
            return state.userdata;
        },
        getAPIBasePath(state) {
            return state.apibasepath;
        },
        getVUEBasePath(state) {
            return state.vuebasepath;
        },
        getWebAppURL(state) {
            return state.webappurl;
        },
        getActiveSearchLimit(state) {
            return state.selectedlimit;
        },
        getCountries(state) {
            state.educountries.filter(function(data, key) {
                if (data.country_Id == "CA" || data.country_Id == "US" || data.country_Id == "IN") {
                    if (!state.countries.includes(data.country_Id)) {
                        state.countries.push(data);
                    }
                }
            })
            return state.countries;
        },
        geteduCountries(state) {
            return state.educountries;
        },
        getVisaOptions(state) {
            return state.visaoptions;
        },
        getTechOptions(state) {
            return state.techoptions;
        },
        getSalaryModes(state) {
            return state.salarymodes;
        },
        getRecruiters(state) {
            return state.recruiters;
        },
        getRecruiterByCompany(state) {
            return state.recruiterByCompany;
        },
        getQualificationData(state) {
            return state.settingsQualification;
        },
        getJobDocumentData(state) {
            return state.settingsJobDocument;
        },
        getWorkAuthorizationData(state) {
            return state.settingsWorkAuthorization;
        },
        getIndustries(state) {
            return state.industryoptions;
        },
        getShiftOptions(state) {
            return state.shiftoptions;
        },
        getUSStates(state) {
            return state.usstateslist;
        },
        getClientContactType(state) {
            return state.clientcontacttypelist;
        },
        getLocalStorageName(state) {
            return state.local_storage_name;
        },
        getAuthToken(state) {
            return localStorage.getItem(state.local_storage_name);
        },
        getDefaultRedirects(state) {
            return state.default_redirects;
        },
        getInterviewStatus(state) {
            return state.interviewStatus;
        },
        getJobStatus(state) {
            return state.jobStatus;
        },
        getInterviewType(state) {
            return state.interviewType;
        },
        getTimeZones(state) {
            return state.timeZones;
        },
        getLanguages(state) {
            return state.languages;
        },
        getClients(state) {
            return state.clients;
        },
        getWorkType(state) {
            return state.workType;
        },
        getRecPlanId(state) {
            return state.recPlanId;
        },
        getGlobalSearchValue() {
            return state.globalSearchValue;
        },
        getJobId(state) {
            return state.globalJobId;
        },
        getShortListedCandidateStatus(state) {
            return state.shortlistedCandidateStatus;
        },
        getPayFrequencyList(state) {
            return state.payFrequencyList;
        },
        getInterviewerScopeList(state) {
            return state.interviewerScopeList;
        },
        getAccountTypeOptions(state) {
            return state.accountTypeOptions;
        },
        getJobExpTypeOptions(state) {
            return state.jobExpTypeOptions;
        },
        getCandidateScopeList(state) {
            return state.candidateScopeList;
        },
        getJobScopeList(state) {
            return state.jobScopeList;
        },
        getCandidateTypeList(state) {
            return state.candidateType;
        },
        editorOptions(state) {
            return {
                hideModeSwitch: true,
                toolbarItems: [
                    'heading',
                    'bold',
                    'italic',
                    'strike',
                    'divider',
                    'hr',
                    'quote',
                    'divider',
                    'ul',
                    'ol',
                    'indent',
                    'outdent',
                    'divider',
                    'table',
                    'image',
                    'link'
                ]
            };
        },
        getTemplateTypesList(state) {
            return state.templateTypesList;
        },
        getSocialMediaTemplateTypesList(state) {
            return state.socialMediaTemplateTypesList;
        },
        getEmailTemplateScope(state) {
            return state.emailTemplateScope;
        },
        getWeblinks(state) {
            return state.weblinks;
        },
        getTagNames(state) {
            return state.tagNames;
        },
        getSourcingChannel(state) {
            return state.sourcingChannel;
        },
        getNameValueList(state) {
            return state.nameValueList;
        },
        getAdditionalFieldsType(state) {
            return state.additionalFieldsType;
        },
        getRecruiterList(state) {
            return state.recruiterlist;
        },
        getCandidatesStatusList(state) {
            return state.candidatesStatusList;
        },
        getCandStatusReport(state) {
            return state.candStatusReport;
        },
        getProjectStatusList(state) {
            return state.projectStatusList;
        },
        getTaskGroupStatusList(state) {
            return state.taskGroupStatusList;
        },
        getNotificationList(state) {
            return state.notificationList;
        },
        getRelocationStatus(state) {
            return state.relocationStatus;
        },
        getTtClients(state) {
            return state.ttClients;
        },
        getCandContactAccess(state) {
            return state.candContactAccess;
        },
        getFacebookClientId(state) {
            return state.facebook_client_id;
        },
        getFacebookRedirectUrl(state) {
            return state.facebook_redirect_url
        },
        getLinkedinClientId(state) {
            return state.linkedin_client_id;
        },
        getLinkedinRedirectUrl(state) {
            return state.linked_redirect_url;
        },
        getGoogleClientId(state) {
            return state.google_client_id;
        },
        getGoogleRedirectUrl(state) {
            return state.google_redirect_url
        },
        getClientCompanyList(state) {
            return state.clientCompanyList;
        },
        getCurrencies(state) {
            return state.currencies;
        },
        getRouteName(state) {
            return state.routeName;
        },
    },
    mutations: {
        saveUserData(state, payload) {
            state.userdata = payload;
        },
        updateActiveSearchLimit(state, limit) {
            state.selectedlimit = limit;
        },
        markAsConfigLoaded(state, flag) {
            state.configloaded = flag;
        },
        updateEduCountries(state, countrieslist) {
            state.educountries = countrieslist;
        },
        updateVisaOptions(state, options) {
            state.visaoptions = options;
        },
        updateTechOptions(state, options) {
            state.techoptions = options;
        },
        updateSalaryModes(state, options) {
            state.salarymodes = options;
        },
        updateRecruiters(state, options) {
            state.recruiters = options;
        },
        updateIndustries(state, options) {
            state.industryoptions = options;
        },
        updateShiftOptions(state, options) {
            state.shiftoptions = options;
        },
        updateStatesList(state, statelist) {
            state.usstateslist = statelist;
        },
        updateClientContactType(state, options) {
            state.clientcontacttypelist = options;
        },
        setInterviewStatus(state, options) {
            state.interviewStatus = options;
        },
        setJobStatus(state, options) {
            state.jobStatus = options;
        },
        setPayFrequencyList(state, options) {
            state.payFrequencyList = options;
        },
        setInterviewType(state, options) {
            state.interviewType = options;
        },
        setCandidateScopeList(state, payload) {
            state.candidateScopeList = payload;
        },
        setJobScopeList(state, payload) {
            state.jobScopeList = payload;
        },
        updateTimeZones(state, timeZones) {
            if (timeZones)
                state.timeZones = timeZones;
            else
                state.timeZones = [];
            state.timeZones.unshift({ id: '', text: 'Select' });
        },
        updateLanguages(state, languageslist) {
            state.languages = languageslist;
        },
        setWorkType(state, options) {
            state.workType = options;
        },
        setClients(state, clientlist) {
            state.clients = clientlist;
        },
        setRecPlanId(state, rpPlanId) {
            state.recPlanId = rpPlanId;
        },
        setGlobalSearchValue(state, options) {
            state.globalSearchValue = options;
        },
        setJobId(state, jobId) {
            state.globalJobId = jobId;
        },
        setShortlistedCandidateStatus(state, SLcandStatusId) {
            state.shortlistedCandidateStatus = SLcandStatusId;
        },
        setInterviewerScopeList(state, payload) {
            state.interviewerScopeList = payload;
        },
        setAccountTypeOptions(state, options) {
            state.accountTypeOptions = options;
        },
        setJobExpTypeOptions(state, options) {
            state.jobExpTypeOptions = options;
        },
        setTemplateTypesList(state, options) {
            state.templateTypesList = options;
        },
        setSocialMediaTemplateTypesList(state, options) {
            state.socialMediaTemplateTypesList = options;
        },
        setEmailTemplateScope(state, options) {
            state.emailTemplateScope = options;
        },
        setWeblinks(state, options) {
            state.weblinks = options;
        },
        setTagNames(state, options) {
            state.tagNames = options;
        },
        setSourcingChannel(state, sourcingChannel) {
            state.sourcingChannel = sourcingChannel;
        },
        setNameValueList(state, data) {
            state.nameValueList = data;
        },
        setRecruiterList(state, data) {
            state.recruiterlist = data;
        },
        setRecruiterByCompany(state, data) {
            state.recruiterByCompany = data;
        },
        setCandidatesStatusList(state, data) {
            state.candidatesStatusList = data;
        },
        setCandStatusReport(state, data) {
            state.candStatusReport = data;
        },
        setProjectStatusList(state, data) {
            state.projectStatusList = data;
        },
        setTaskGroupStatusList(state, data) {
            state.taskGroupStatusList = data;
        },
        setQualificationData(state, data) {
            state.settingsQualification = data;
        },
        setJobDocumentData(state, data) {
            state.settingsJobDocument = data;
        },
        setWorkAuthorizationData(state, data) {
            state.settingsWorkAuthorization = data;
        },
        setCandidateTypeList(state, data) {
            state.candidateType = data;
        },
        refreshPagination(state) {
            state.selectedlimit = 10;
            state.paginationconfig.currentpage = 1;
            state.paginationconfig.totalpages = 1;
            state.paginationconfig.totalrecords = 1;
            // console.log(state.paginationconfig);
        },
        setNotificationList(state, data) {
            state.notificationList = data;
        },
        setRelocationStatus(state, data) {
            state.relocationStatus = data;
        },
        setTtClients(state, data) {
            state.ttClients = data;
        },
        setCandContactAccess(state, data) {
            state.candContactAccess = data;
        },
        setClientCompanyList(state, data) {
            state.clientCompanyList = data;
        },
        setCurrencyList(state, data) {
            state.currencies = data;
        },
    },
    actions: {
        reloadSkills(context) {
            return new Promise((resolve, reject) => {
                axios.get(context.getters.getAPIBasePath + '/commonresource/technologylist')
                    .then(response => {
                        if (response.data.status) {
                            context.commit('updateTechOptions', response.data.technologylist);
                            resolve(response);
                        }
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        reloadIndustries(context) {
            return new Promise((resolve, reject) => {
                axios.get(context.getters.getAPIBasePath + '/commonresource/industrylist')
                    .then(response => {
                        if (response.data.status) {
                            context.commit('updateIndustries', response.data.industries);
                            resolve(response);
                        }
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        reloadClient(context) {
            return new Promise((resolve, reject) => {
                axios.get(context.getters.getAPIBasePath + '/commonresource/clientlist')
                    .then(response => {
                        if (response.data.status) {
                            context.commit('setClients', response.data.clientlist);
                            resolve(response);
                        }
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        reloadSourcingChannel(context) {
            return new Promise((resolve, reject) => {
                axios.get(context.getters.getAPIBasePath + '/commonresource/list/all')
                    .then(response => {
                        if (response.data.status) {
                            context.commit('setSourcingChannel', response.data.sourcingChannel);
                            resolve(response);
                        }
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        phoneValidation() {
            return new Promise((resolve, reject) => {
                axios.get('http://apilayer.net/api/validate?accesskey=' + this.access_key + '&number=' + this.number)
                    .then(response => {
                        /*if(response.data.status){
                            context.commit('updateIndustries',response.data.industries);
                            resolve(response);
                        }
                        resolve(response);*/
                        console.log("PhoneValidation " + response.data)
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        doLogout(context) {
            localStorage.clear();
            localStorage.removeItem(context.getters.getLocalStorageName);
            localStorage.removeItem("taskTime");
            localStorage.removeItem("timerStatus");
            localStorage.removeItem("taskActivity");
            localStorage.removeItem("taskId");
            localStorage.removeItem("taskTypeId");
            localStorage.removeItem("activityLogId");
            localStorage.removeItem('interviewId');
            localStorage.removeItem('emailAlreadyExists');
            context.commit("markAsConfigLoaded", 0);
            context.commit("setNotificationList", { taskId: 0 });
            window.location.href = process.env.VUE_APP_MAIN_URL;
        },
        setupLogin(context, resultset) {
            return new Promise((resolve, reject) => {
                localStorage.removeItem(context.getters.getLocalStorageName);
                context.commit("saveUserData", resultset.data);
                localStorage.setItem(context.getters.getLocalStorageName, resultset.data.token);
                resolve("true");
            });
        },
        updateNotificationList(context, resultset) {
            return new Promise((resolve, reject) => {
                localStorage.removeItem(context.getters.getNotificationList);
                context.commit("setNotificationList", resultset);
                // localStorage.setItem(context.getters.getNotificationList, resultset);
                resolve("true");
            });
        },
    }
}