import { render, staticRenderFns } from "./DomainSettings.vue?vue&type=template&id=fed17022&scoped=true&"
import script from "./DomainSettings.vue?vue&type=script&lang=js&"
export * from "./DomainSettings.vue?vue&type=script&lang=js&"
import style0 from "./DomainSettings.vue?vue&type=style&index=0&id=fed17022&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fed17022",
  null
  
)

export default component.exports