<template>
    <div class="card">
        <div class="card-header p-2">Resume Upload</div>
        <div class="card-body p-2">
            <div class="basic-block clone-preference">
                <div class="row ten-columns pos-relative clone filled2">
                    <div class="col-md-6 col-sm-6 col-lg=6 mb-10-mr">
                        <div class="row ten-columns" :class="{ 'noaddedmore' : resumeinfo.viewonly == 1}">
                            <div class="col-md-12 col-sm-12 col-12">
                                <input class="upload" ref="addcandidate_resumeattach_resume" id="candidateadd_resume_fileupload" type="file" accept=".doc,.docx,.pdf" v-on:change="handleFileUpload"></input>
                            </div>
                            <div class="col-md-12 col-sm-12 col-12">
                                <span>Supports only PDF, Doc., Docx file types</span>
                            </div>
                        </div>

                        <div class="row ten-columns" v-if="resumeinfo.filename != null && resumeinfo.filename.length > 0 "> 
                            <a v-if="(resumeinfo.downloadResumeCount != 0 || resumeinfo.isViewed==1)" :href="resumeinfo.fileurl" target="_blank" @click="addCandidateDownload">{{resumeinfo.filename}}</a>                       
                            <span v-if="resumeinfo.downloadResumeCount == 0 && resumeinfo.isViewed == 0" class="max_limit">* Maximum Limit Reached Update Your Account </span>
                            <span v-if="resumeinfo.downloadResumeCount==null " class="max_limit">* Can't Download Resume Update Your Account </span>
                            <span v-if="resumeinfo.downloadResumeCount!=null && resumeinfo.isViewed == 0 && resumeinfo.expiredDate == null" class="max_limit">* Your Account Expired </span>
                        </div>
                        <div v-else class="row ten-columns">                            
                            <span> No resume found </span>
                        </div>


                    </div>
                </div>
                <div v-if="(resumeinfo.isViewed==1)" class="row ten-columns">
                    <iframe :src="docSrc" frameBorder="0" style="background-color: #FFFFFF" width="1000" height="800" allowfullscreen></iframe>
                    <!-- <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=resumeinfo.fileurl' width='1066px' height='623px' frameborder='0'>This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe> -->
                </div>
            </div>
        </div>
        <div class="card-header p-2">Other Attachments</div>
        <div class="card-body p-2">
            <div class="basic-block clone-preference">
                <div class="row ten-columns pos-relative clone filled2">
                    <div class="col-md-6 col-sm-6 col-lg=6 mb-10-mr">
                        <div v-if="(resumeinfo.downloadResumeCount != 0 || resumeinfo.isViewed==1)" class="row ten-columns" v-for="(file, index) in multipleFile">
                            <span v-if="file.attachmentTypes != '' ">{{file.attachmentTypes}} :</span>
                            <a href="javascript:">{{file.multipleFileNames}}</a>
                        </div>
                        <span v-if="multipleFile==undefined || multipleFile.length==0">No attachment found</span>
                        <!-- <span v-if="resumeinfo.resumeViewCount ==0" class="max_limit">* Maximum Limit Reached For View Resume Update Your Account</span> -->
                        <span v-if="resumeinfo.downloadResumeCount == 0 && resumeinfo.isViewed == 0 && multipleFile.length!=0" class="max_limit">* Maximum Limit Reached Update Your Account </span>
                        <span v-if="resumeinfo.downloadResumeCount==null && multipleFile.length!=0 " class="max_limit">* Can't Download Attachment Update Your Account </span>
                        <span v-if="resumeinfo.downloadResumeCount!=null && resumeinfo.isViewed == 0 && resumeinfo.expiredDate == null && multipleFile.length!=0 " class="max_limit">* Your Account Expired </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    // props: {
    //     resumeinfo: Object,       
    //     multipleFile: Array,
    // },
    props: ['resumeinfo', 'multipleFile', 'candidateid'],
    data() {
        return {
            allowedfiletypes: ['pdf', 'doc', 'docx'],
            docSrc: "",
            ttClientAccountId: localStorage.getItem('clientAccountId'),
        }
    },
    watch: {
        'resumeinfo': {
            handler: function(value) {
                console.log(this.resumeinfo.fileurl);
                this.bindIframeSrc();
                
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        console.log('resume attachement')   
        this.bindIframeSrc();
        
       
    },
    methods: {
      
        bindIframeSrc: function() {
            if(this.resumeinfo.fileurl){
                this.docSrc = "https://docs.google.com/gview?url=" + this.resumeinfo.fileurl + "&embedded=true";
            }
            /* if(this.resumeinfo.filename!="" ) {
                 this.updateResumeCount();
            } */
        },
        handleFileUpload() {
            let fileelement = this.$refs.addcandidate_resumeattach_resume.files[0];
            let ext = fileelement.name.substring(fileelement.name.lastIndexOf('.') + 1);
            if ($.inArray(ext, this.allowedfiletypes) !== -1) {
                this.resumeinfo.file = this.$refs.addcandidate_resumeattach_resume.files[0];
            } else {
                iziToast.info({
                    message: this.$config.FileExtNotSup,
                    position: 'topRight'
                });
                $("#candidateadd_resume_fileupload").val('');
                this.resumeinfo.file = '';
            }
        },
        addCandidateDownload() {
             console.log('this.resumeinfo.downloadResumeCount',this.candidateid);
             if (this.resumeinfo.downloadResumeCount > 0) {

                axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/add/candidate/download', { candidateId: this.candidateid, candidateTtClientAccountId:this.resumeinfo.ttClientAccountId })
                .then(response => {
                    let result = response.data;
                    this.$emit('refresh-candidate');
                })
                .catch(error => {
                    console.log(error);
                    this.unAuthorized(error);
                });         
                       
                }
        },
         updateResumeCount() {        
               axios.post(this.$store.getters.getAPIBasePath + '/clientresource/update/clientresumeviewcount/' + this.$store.getters.currentUser.ttClientAccountId, { candidateid: this.candidateid })
                .catch(error => {
                    console.log(error);
                    this.unAuthorized(error);
                });                        
        },
    }
}

</script>
