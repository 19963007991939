<template>
  <div>
    <div class="input-group phone-border"  v-bind:class="[ (mandatory == true) ? 'required-field-block' : '']">
      <select
        class="form-control box-none col-sm-3 pl-0 pr-0  max-w-45 border-bottom-0"          
        @change="updatePhoneNumber"
        v-model="countryCode"
      >
        <option value="+1">+1</option>
        <option value="+91">+91</option>
      </select>
      <input
        type="text"
        v-mask="'(###)-###-####'"
        class="form-control box-none border-bottom-0 pl-0 pr-0" 
        v-model="phoneNo"
        @input="updatePhoneNumber"
        placeholder="Phone No"
      ></input>
      <span v-if="mandatory" class="required-icon"></span>
    </div>
    <label v-if="mandatory && $v.phoneNo.$error && !$v.phoneNo.required" class="text-danger">Please enter Phone No.</label>                                                                           
    <label v-if="$v.countryCode.$error && !$v.countryCode.requiredIf" class="text-danger">Please enter phone number with country code</label>                                 
  </div>
</template>
<script>
import {  required,  email,  minLength,  maxLength,  sameAs, requiredIf} from "vuelidate/lib/validators";
export default {
  props: {
    value : String,
    mandatory: {
          type: Boolean,
          default: false
    },
  },
  computed: {

  },
  watch: {
      'value': {
          handler: function() {
              this.assignValue();
          },
          deep: true,
          immediate: true
      },
      'mandatory': {
          handler: function() {              
          },
          deep: true,
          immediate: true
      },
  },
  data() {
    return {
        countryCode:"+1",
        phoneNo:"",
        phoneNumber:""
    };
  },
  validations: {
        phoneNo:{
          required
        },
        countryCode:{
            required: requiredIf(function() {
            return this.phoneNo != null && this.phoneNo.length > 0;
        })
        }

    },
  mounted() {
    this.assignValue();
 },
  methods: {
    assignValue: function(){
      if(this.value != null && this.value.length > 0){
        let phoneNo = this.value.replace('undefined','');
        if (phoneNo != null && phoneNo.trim().startsWith("+")) {
            let phoneData = phoneNo.trim().split(" ");
            this.countryCode = phoneData[0];
            this.phoneNo = phoneData[1];
        }else{
            this.phoneNo = phoneNo;
            this.countryCode = "";
        }  
      }
      else{
        this.phoneNo = "";
      }
    },
    validatePhoneNumber(){ 
      if(this.mandatory){
        this.$v.$touch();
        if (this.$v.$invalid) {  
            return false;
        }
        return true;
      } else{ 
        this.$v.countryCode.$touch();
        if (this.$v.countryCode.$invalid) {  
            return false;
        }
        return true;
      }

    },
    updatePhoneNumber(e) {
      if(!/\s/g.test(this.phoneNo)){
        if(this.phoneNo != null && this.phoneNo != '' && this.countryCode != null && this.countryCode != '' && this.phoneNo != '(___)-___-____' && this.phoneNo != '(')
        {
          let x = this.phoneNo.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
          this.phoneNo= !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '-' + x[3] : '');
          this.phoneNumber = this.phoneNo;
          this.phoneNumber = this.countryCode +' ' + this.phoneNo;
        }
        else if(this.phoneNo != null && this.phoneNo != '' && this.phoneNo != '(___)-___-____'&& this.phoneNo != '(')
        {
          this.phoneNumber = this.phoneNo;
        }
        else if(this.phoneNo === '(___)-___-____' || this.phoneNo == '('|| this.phoneNo == '')
        {
        this.phoneNumber = '';
        }
      }
      else{
        if(this.phoneNo != null && this.phoneNo != '' && this.countryCode != null && this.countryCode != '' && this.phoneNo != '(___)-___-____' && this.phoneNo != '(')
        {
          let x = this.phoneNo.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
          this.phoneNo= !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '-' + x[3] : '');
          this.phoneNumber = this.countryCode +' ' + this.phoneNo;
        }
        else if(this.phoneNo != null && this.phoneNo != '' && this.phoneNo != '(___)-___-____'&& this.phoneNo != '(')
        {
          let x = this.phoneNo.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
          this.phoneNo= !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '-' + x[3] : '');
          this.phoneNumber = this.phoneNo;
        }
        else if(this.phoneNo === '(___)-___-____' || this.phoneNo == '('|| this.phoneNo == '')
        {
        this.phoneNumber = '';
        }
      }
      this.$emit("phone-input", this.phoneNumber);

    }
  }
};
</script>