<template>
    <div id="main" class="mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model">
                <div class="row no-gutters">
                    <b-tabs v-model="currentClientTab" small card class="tab-list driver-tab-list">
                        <b-tab title="Lead Details">
                            <VendorInfo :vendorinfo="vendorInfo" ref="vendorinfocomponent"></VendorInfo>
                        </b-tab>
                    </b-tabs>
                </div>
                <div class="mt-3">
                    <div class="row">
                        <div class="col-sm-12">
                            <a href="Javascript:void(0);" @click="saveCurrentTab" class="btn btn-primary btn-sm float-right">Save</a>
                            <a href="Javascript:void(0);" @click="getPrevUrl" class="btn btn-secondary btn-sm mr-2  float-right">
                                Cancel
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import VendorInfo from './blocks/addvendor/VendorInfo';
export default {
    components: {
        VendorInfo,

    },
    data() {
        return {
            currentClientTab: 0,
            maxtabs: 3,
            vendorInfo: {
                companyName: '',
                email: '',
                phone: '',                
                // phoneNumber: '',
                websiteUrl: '',
                industryId: '',
                linkedinUrl:'',
                facebookUrl:'',
                twitterUrl:'',
                instagramUrl:'',
                street:'',
                cityName:'',
                stateName:'',
                countryId:'',
                zipCode:'',
                yearFounded:'',
                // countryCode: '+91',
            },
        }
    },
    mounted() {

    },
    methods: {
        saveCurrentTab() {
            switch (this.currentClientTab) {
                case 0:
                    if (this.$refs.vendorinfocomponent.validatevendorinfo()) {
                        this.saveVendorInfo();
                    }
                    break;
                default:
                    break;
            }
        },
        saveVendorInfo() {
            // let phoneNumber = '';
            // if (this.vendorInfo.phone != '') {
            //     phoneNumber = this.vendorInfo.countryCode + " " + this.vendorInfo.phone;
            //     this.vendorInfo.phoneNumber = phoneNumber;
            // }
            
            axios.post(this.$store.getters.getAPIBasePath + '/lead/add', this.vendorInfo)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.LeadInfoCreSuc,
                        position: 'topRight'
                    });               

                    this.$router.push('/lead/list/');
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.LeadInfoCreErr,
                        position: 'topRight'
                    });
                });
        },
    }
}

</script>
